import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";
import {
  dispatchHealthQuickQuote,
  dispatchMotorQuote,
  dispatchProcessFlowHealth,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetHealthQuickQuote,
  dispatchResetMotorQuote,
  dispatchResetProcessFlow,
  dispatchResetProcessFlowHealth,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  dispatchUserData,
  dispatchUserDetails,
  resetHealthQuickQuoteResults,
  resetProcessFlowMotor,
  resetQuickQuoteResults,
  resetSelectedPlan,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehiclePincode,
  getVehiclePincodeHealth,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant,
} from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelect";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import {
  CUSTOMER_TYPE,
  FOUR_WHEELER_VEHICLE_TYPE,
  POLICY_STATUS,
  POLICY_TYPE,
  TWO_WHEELER_VEHICLE_TYPE,
  VEHICLE_TYPE,
  caseStatus,
} from "../utility/constants";
import { useCookies } from "react-cookie";
import ReactInputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import { PostDataWithToken } from "../../api/apiHelper";
import {
  calculatePreviousTPEndDate,
  differenceInYears,
  extractPincode,
  handlePolicyType,
  lengthMethod,
} from "../../store/reducer/userReducers";
import { HiMiniPencil } from "react-icons/hi2";
import { EventBusy } from "@mui/icons-material";

const VehicleDetailsModal = ({
  show,
  setModal,
  vehicleDetails,
  setActiveStep,
  formatRegistrationNumber,
  setVehicleType,
}) => {
  if (!vehicleDetails) return null;
  const {
    registrationNumber,
    rtoCode,
    vehicleType,
    manufaturingDate,
    mobileNumber,
    firstName,
    lastName,
    registrationDate,
    variantCode,
    makeId,
    modelId,
    prevInsurerCode,
    prevPolicyEndDate,
    email,
    chassisNumber,
    engineNumber,
    hpnBank,
    pucNumber,
    pucValidUpto,
    completeAddress,
    customerType,
    isPrevInsurerInProposal,
    prevPolicyNumber,
  } = vehicleDetails?.data || {};
  console.log("vehicleDetailsModal Data", vehicleDetails);

  //   const registrationNumber = vehicleDetails?.data?.registrationNumber || "N/A";

  const [healthModal, setHealthModal] = useState(false);
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm();
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    watch,
    formState: { errors: errors1 },
    setValue,
    getValues,
    clearErrors: clearErrors1,
  } = useForm();
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    watch: watch2,
    clearErrors,
    formState: { errors: errors2 },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedBox, setSelectedBox] = useState("");
  const [pincodeData, setPincodeData] = useState([]);
  const [insurerData, setInsurerData] = useState([]);
  const [age, setAge] = useState([]);
  const [filteredPincodes, setFilteredPincodes] = useState([]);
  //   const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!show);
  const [retentionModal, setRetentionModal] = useState(false);
  const toggleRetentionModal = () => setRetentionModal(!retentionModal);
  const [currentSteps, setCurrentSteps] = useState(1);

  const [submit, setSubmit] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const userDetails = useSelector((state) => state?.root?.userDetails);

  const params = new URLSearchParams(window.location.search);
  console.log("PARAMSSSS", params.get("employeeId"));

  const [differenceYear, setDifferenceYear] = useState(0);

  useEffect(() => {}, [selectedBox === "Pvt Car", selectedBox === "MotorBike"]);

  const [variantData, setVariantData] = useState([]);
  const [variantDetails, setVariantDetails] = useState(null);
  const [maxRegDate, setMaxRegDate] = useState("");
  const [prevPolicyDate, setPrevPolicyDate] = useState(prevPolicyEndDate || "");

  const [previousInsurerDetails, setPreviousInsurerDetails] = useState(null);
  const [nameChange, setNameChange] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [maskedNumber, setMaskedNumber] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const applyMask = (phoneNumber) => {
    if (!phoneNumber) return "";

    // Get the first two and last two digits and mask the middle ones
    const visibleStart = phoneNumber.slice(0, 2);
    const visibleEnd = phoneNumber.slice(-2);
    const maskedMiddle = "x".repeat(phoneNumber.length - 4); // Mask the middle part

    return visibleStart + maskedMiddle + visibleEnd;
  };

  useEffect(() => {
    // handlePreviousTpEndDate(prevPolicyEndDate, registrationDate);
    const maskNumber = applyMask(mobileNumber);
    setMaskedNumber(maskNumber);
    !maskNumber && setIsEditable(true);
    console.log("maskNumber", maskNumber);
    const name = firstName.length > 1 ? firstName.split(" ") : firstName;
    setValue("fullName", firstName + " " + lastName);
    setNameValue(name[0]);
    maskNumber && setValue("mobileNumber", maskNumber);
    console.log("----", getValues("mobileNumber"));
  }, []);

  useEffect(() => {
    console.log("prevPolicyDate", prevPolicyDate);
  }, [prevPolicyDate]);

  useEffect(() => {
    getVehicleVariant({
      makeId: parseInt(makeId),
      modelId: parseInt(modelId),
      vehicleType: vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        console.log("data getVehicleVariant", data);
        // let fuelTypeArr = data.filter(
        //   (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        // );
        // let j = 0;
        // let fuelarr = [];
        // while (j < fuelTypeArr.length) {
        //   fuelarr.push(fuelTypeArr[j].fuelType);
        //   j++;
        // }
        // setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item.variant}(${item.cc}cc)`,
            value: item.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }
        setVariantData(arr);
        console.log("setVariantData", arr);

        setVariantDetails(
          arr.find((vehicle) => vehicle?.value === parseInt(variantCode))
        );

        // if (motorRequest?.vehicleType === "4w") {
        //   handleStepSelection(activeStep + 1);
        // } else {
        //   dispatchMotorQuote("fuelType", fuelarr[0]);
        //   handleStepSelection(activeStep + 2);
        // }
      }
    });
  }, []);

  useEffect(() => {
    console.log("veriantDEtails-----", variantDetails);
    console.log("veriantDEtails-----", vehicleType);
  }, [variantDetails]);

  const handlePreviousTpEndDate = (prevPolicyEndDate, registrationDate) => {
    let prevTPPolicyEndDate;
    let prevTPPolicyNumber;
    let prevTPInsurerCode;
    const difference = new Date(prevPolicyEndDate) - new Date(registrationDate);
    const diffInYears = (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(
      4
    );
    const isGreaterThanCurrentDate =
      new Date(prevPolicyEndDate) > new Date(registrationDate);
    console.log("isGreaterThanCurrentDate", isGreaterThanCurrentDate);

    console.log("diffInYears handlePreviousTpEndDate", diffInYears);
    if (
      (diffInYears >= 4.8 && diffInYears <= 5.2 && isGreaterThanCurrentDate) ||
      (diffInYears >= 2.8 && diffInYears <= 3.2 && isGreaterThanCurrentDate)
    ) {
      prevTPPolicyEndDate = prevPolicyEndDate;
      prevTPPolicyNumber = prevPolicyNumber;
      prevTPInsurerCode = prevInsurerCode;
      return { prevTPPolicyEndDate, prevTPPolicyNumber, prevTPInsurerCode };
    } else return null;
  };

  const handleUserDetails = (data) => {
    console.log("Form Data Submitted:", data);
    if (data?.mobileNumber === maskedNumber) {
      setValue("mobileNumber", mobileNumber);
    }

    // Reset necessary state/actions
    dispatchResetHealthQuickQuote();

    let newData = {
      firstName: "",

      lastName: "",

      mobileNumber:
        data?.mobileNumber === maskedNumber ? mobileNumber : data?.mobileNumber,
    };

    const nameParts = data?.fullName.split(" ");
    console.log("nameParts", nameParts);

    if (nameParts.length === 1) {
      // Only one part, treat it as first name
      newData.firstName = nameParts[0];
    } else if (nameParts.length === 2) {
      // Two parts, treat them as first and last names
      newData.firstName = nameParts[0];
      newData.lastName = nameParts[1];
    } else {
      // More than two parts
      // newData.firstName = nameParts.slice(0, -1).join(" ");
      // newData.lastName =
      //   nameParts[nameParts.length - 1] === ""
      //     ? nameParts[nameParts.length - 2]
      //     : nameParts[nameParts.length - 1];
      // newDataStructure.middleName = newNameParts.slice(1, -1).join(" ");

      const sanitizedNameParts = nameParts.filter((part) => part.trim() !== "");

      // Extract first name (all parts except the last one)
      newData.firstName = sanitizedNameParts.slice(0, -1).join(" ");

      // Extract last name (last part if not empty, otherwise second-to-last part)
      newData.lastName =
        sanitizedNameParts[sanitizedNameParts.length - 1] === ""
          ? sanitizedNameParts[sanitizedNameParts.length - 2]
          : sanitizedNameParts[sanitizedNameParts.length - 1];
    }

    console.log("newData: ", newData);

    for (let key in newData) {
      dispatchMotorQuote(key, newData[key]);
    }

    PostDataWithToken("admin/checkCustomer", {
      phoneNumber:
        data?.mobileNumber === maskedNumber ? mobileNumber : data?.mobileNumber,
    }).then((response) => {
      if (
        response?.data?.success === false &&
        response?.data?.message === "Customer Does not exist with this Number!!"
      ) {
        const min = 1000000000000;
        const max = 9999999999999;
        const UniqueId = Math.floor(Math.random() * (max - min + 1) + min);

        const newUserData = {
          name: data?.fullName,
          // emailId: data?.Email,
          phoneNumber:
            data?.mobileNumber === maskedNumber
              ? mobileNumber
              : data?.mobileNumber,
          customerUniqueNumber: UniqueId,
        };

        PostDataWithToken("admin/add_customer", newUserData).then(
          (response) => {
            dispatchMotorQuote(
              "customerUniqueNumber",
              response?.data?.customer_unique_number
            );
            if (!cookies.customerLeadId) {
              const now = new Date();
              // Set the cookie to expire in 2 hours
              const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
              setCookie("customerLeadId", motorRequest?.customerLeadId, {
                expires,
              });
            }
          }
        );
      } else if (
        response?.success === true &&
        response?.message === "Customer already Exists!!"
      ) {
        dispatchMotorQuote(
          "customerUniqueNumber",
          response?.data?.customer_unique_number
        );
        if (!cookies.customerLeadId) {
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", motorRequest?.customerLeadId, {
            expires,
          });
        }
      }
    });
    dispatch(resetQuickQuoteResults());
    localStorage.removeItem("addOnfilter");
    localStorage.removeItem("accessoriesFilter");
    localStorage.removeItem("accidentFilter");

    handleSaveVehicleDetails();
    setSubmit(true);
    // setTimeout(() => {
    //   setSubmit(false);
    //   navigate(`/quotelist/${motorRequest?.customerLeadId}`);
    // }, 1000);
  };

  const handleNotKnownVehicleDetails = () => {
    let leadId = motorRequest?.customerLeadId;
    console.log("leadId", leadId);
    // debugger;
    // dispatchResetMotorQuote();
    setVehicleType(vehicleType);
    dispatchMotorQuote("vehicleType", vehicleType);
    dispatchMotorQuote("isVahanDetailsChanged", true);
    // dispatchMotorQuote("customerLeadId", leadId);

    toggleModal(!show);
    // If registration number exists, proceed to the next step
    setActiveStep(2);
    localStorage.clear();
  };

  const isValidYear = (date) => /^\d{4}$/.test(date); // Check if it's just a year (4 digits)

  const handleManufacturingDate = (manufaturingDate, registrationDate) => {
    let newMfgDate = null;

    // Case 1: If it's an empty string, null, or just a year (e.g., "2011")
    if (
      !manufaturingDate ||
      !manufaturingDate.trim() ||
      isValidYear(manufaturingDate)
    ) {
      // Use the registration date (falling back to registration month and year)
      if (registrationDate) {
        const regDate = moment(registrationDate); // Parse registration date using moment
        newMfgDate = regDate.startOf("month").format("YYYY-MM-DD"); // Get 1st of registration month and year
      }
    }
    // Case 2: If it's in MM/YYYY format
    else if (/^(\d{1,2})\/\d{4}$/.test(manufaturingDate)) {
      const isMMYYYY = true;
      newMfgDate = moment(manufaturingDate.replace(/^0\//, "01/"), "MM/YYYY")
        .startOf("month")
        .format("YYYY-MM-DD"); // Ensure it's the 1st of the given month and year
    }
    // Case 3: If it's a valid full date in "YYYY-MM-DD" format, return as is
    else if (moment(manufaturingDate, "YYYY-MM-DD", true).isValid()) {
      newMfgDate = moment(manufaturingDate).format("YYYY-MM-DD");
    }

    // Case 4: Invalid date format, return null
    if (!newMfgDate) {
      console.log("Invalid manufacturing date format.");
      return null;
    }

    console.log("Processed Manufacturing Date:", newMfgDate);
    return newMfgDate;
  };

  const handleSaveVehicleDetails = () => {
    const mfgDate = handleManufacturingDate(manufaturingDate, registrationDate);
    console.log("mfgDate", mfgDate);
    // const isMMYYYY = /^(\d{1,2})\/\d{4}$/.test(manufaturingDate);
    // console.log("isMMYYYY", isMMYYYY);
    // const mfgDate = isMMYYYY
    //   ? moment(manufaturingDate.replace(/^0\//, "01/"), "MM/YYYY")
    //       .startOf("month")
    //       .format("YYYY-MM-DD")
    //   : manufaturingDate;
    // console.log("mfgDate", mfgDate);

    const prevTPPolicyDetails = handlePreviousTpEndDate(
      prevPolicyEndDate,
      registrationDate
    );
    console.log("prevTPPolicyEndDate", prevTPPolicyDetails);
    prevTPPolicyDetails?.prevTPPolicyEndDate &&
      dispatchMotorQuote(
        "prevTPPolicyEndDate",
        prevTPPolicyDetails?.prevTPPolicyEndDate
      );
    prevTPPolicyDetails?.prevTPPolicyNumber &&
      dispatchMotorQuote(
        "prevTPPolicyNumber",
        prevTPPolicyDetails?.prevTPPolicyNumber
      );
    prevTPPolicyDetails?.prevTPInsurerCode &&
      dispatchMotorQuote(
        "prevTPInsurerCode",
        prevTPPolicyDetails?.prevTPInsurerCode
      );

    if (completeAddress) {
      const addressLine1 = lengthMethod(completeAddress, 20);
      const addressLine2 = lengthMethod(addressLine1.remainingCharacters, 30);
      const addressLine3 = lengthMethod(addressLine2.remainingCharacters, 100);
      const pincode = lengthMethod(completeAddress)?.pincode;
      addressLine1 && dispatchMotorQuote("addressLine1", addressLine1?.ans);
      addressLine2 && dispatchMotorQuote("addressLine2", addressLine2?.ans);
      addressLine3 && dispatchMotorQuote("addressLine3", addressLine3?.ans);
      pincode && dispatchMotorQuote("pincode", parseInt(pincode));
    }

    dispatchMotorQuote("savedDataForVahanDetails", vehicleDetails);
    rtoCode && dispatchMotorQuote("rtoCode", rtoCode);
    registrationNumber &&
      dispatchMotorQuote("registrationNumber", registrationNumber);
    mfgDate && dispatchMotorQuote("manufaturingDate", mfgDate);
    vehicleType && dispatchMotorQuote("vehicleType", vehicleType);
    registrationDate &&
      dispatchMotorQuote("registrationDate", registrationDate);
    variantCode && dispatchMotorQuote("variantId", variantCode);
    makeId && dispatchMotorQuote("makeId", makeId);

    modelId && dispatchMotorQuote("modelId", modelId);

    chassisNumber && dispatchMotorQuote("chassisNumber", chassisNumber);
    engineNumber && dispatchMotorQuote("engineNumber", engineNumber);
    pucNumber && dispatchMotorQuote("isValidPuc", true);
    pucNumber && dispatchMotorQuote("pucNumber", pucNumber);
    pucValidUpto && dispatchMotorQuote("pucValidUpto", pucValidUpto);
    customerType && dispatchMotorQuote("customerType", customerType);
    isPrevInsurerInProposal &&
      dispatchMotorQuote("isPrevInsurerInProposal", isPrevInsurerInProposal);
    prevPolicyNumber &&
      dispatchMotorQuote("prevPolicyNumber", prevPolicyNumber);

    dispatchMotorQuote("makeName", variantDetails?.make);
    dispatchMotorQuote("modelName", variantDetails?.model);
    dispatchMotorQuote("variantName", variantDetails?.label);
    dispatchMotorQuote("fuelType", variantDetails?.fuelType);
    // dispatchMotorQuote("insurerCode", 14);
    // ------------
    prevInsurerCode &&
      dispatchMotorQuote("insurerCode", parseInt(prevInsurerCode));
    prevPolicyEndDate &&
      dispatchMotorQuote("prevPolicyEndDate", prevPolicyEndDate);

    email && dispatchMotorQuote("email", email);
    const customerLeadId = motorRequest?.customerLeadId;
    const fullName = firstName + " " + lastName;
    saveToLocalStorage(
      "vehicleDetailsResponse",
      vehicleDetails,
      customerLeadId,
      15,
      variantDetails?.make,
      variantDetails?.model,
      variantDetails?.label,
      variantDetails?.fuelType,
      registrationDate,
      fullName,
      vehicleType
    );
    const policyType = handlePolicyType(registrationDate, vehicleType);
    console.log("newPolicyType", policyType);
    navigate(`/quotelist/${motorRequest?.customerLeadId}`, {
      state: { vahaanJourney: true, policyType },
    });
  };

  const saveToLocalStorage = (
    key,
    value,
    customerLeadId,
    expirationInMinutes,
    make,
    model,
    variant,
    fuel,
    registrationDate,
    fullName,
    vehicleType
  ) => {
    const expirationTime =
      new Date().getTime() + expirationInMinutes * 60 * 1000; // Calculate expiration time
    const dataToSave = {
      value: {
        ...value, // Save response data
        customerLeadId, // Include customerLeadId
        make,
        model,
        variant,
        fuel,
        registrationDate,
        fullName,
        vehicleType,
      },
      expirationTime, // Save expiration time
    };
    localStorage.setItem(key, JSON.stringify(dataToSave)); // Save data as a string in local storage
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input date format (DD/MM/YYYY)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);
      console.log("handleInputChange", date);
      if (!isNaN(date.getTime())) {
        setPrevPolicyDate(date);
        setValue("prevPolicyDate", date); // Update form value
      }
    } else {
      setPrevPolicyDate(null);
      setValue("prevPolicyDate", ""); // Clear value if invalid
    }
  };

  const handlePrevPolicyEndDate = (val) => {
    const date = moment(val).format("YYYY-MM-DD");
    console.log("handleDateChange", date);
    if (date) {
      setPrevPolicyDate(date);
      setValue("prevPolicyEndDate", date);
    } else {
      setPrevPolicyDate(null);
      setValue("prevPolicyEndDate", "");
    }
  };
  useEffect(() => {
    // getVehicleRto().then((response) => {
    //   console.log(response, "response");
    //   if (response.status === "success") {
    //     let data = response?.data;
    //     let i = 0;
    //     let arr = [];
    //     while (i < data?.length) {
    //       let item = data[i];
    //       arr.push({
    //         label: item.registered_city_name + " (" + item.RTO_Code + ")",
    //         value: item.RTO_Code,
    //         registered_state_name: item.registered_state_name,
    //         id: item.id,
    //         registered_city_name: item.registered_city_name,
    //       });
    //       i++;
    //     }
    //     setRtoData(arr);
    //   }
    // });

    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            // value: item.Digit_Code,
            value: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
        setPreviousInsurerDetails(
          arr.find((vehicle) => vehicle?.value === parseInt(prevInsurerCode))
        );
      }
    });
  }, []);

  const handleEditClick = () => {
    setValue("mobileNumber", mobileNumber);
    setIsEditable(true);
  };

  // const handleTpPolicyDetails = (regDate) => {
  //   const difference = new Date(prevPolicyEndDate) - new Date(regDate);
  //   const diff = (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(4);
  //   console.log("difference handleTpPolicyDetails", diff, difference);
  // };

  // const tpEndDATE = calculatePreviousTPEndDate(
  //   registrationDate,
  //   prevPolicyEndDate,
  //   vehicleType
  // );
  // console.log("tpEndDATE", tpEndDATE);

  return (
    <div className="container">
      <div className="d-flex hero-container">
        {/* <div className="youthImage">
          <img src="assets/images/young.png" />
        </div> */}
      </div>
      {/* -----------------------------MODAL BOX-------------------------- */}
      <Modal
        isOpen={show}
        className="car_modal"
        toggle={toggleModal}
        size="xl"
        backdrop="static"
        centered
        // className="vehicleInfoModal"
      >
        <ModalBody>
          {currentSteps === 1 && (
            <div className="modal-vh-info">
              <div className="modal_heading w-100 mb-3">
                <div
                  onClick={toggleModal}
                  className="v2_close false"
                  style={{ cursor: "pointer" }}
                >
                  <a>
                    {/* <img
                      src="/assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    /> */}
                    <RxCross2 style={{ color: "#fff" }} />
                  </a>
                </div>
              </div>
              <form onSubmit={handleSubmit1(handleUserDetails)}>
                <div className="col-12">
                  <div className="row d-flex justify-content-between vahan-details-modal">
                    <div className="col-md-6 modal-right">
                      <div>
                        <div className="per_info">
                          <h1>
                            {" "}
                            {/* Hi kamlesh{" "} */}
                            Hi {nameValue && nameValue.split(" ")[0]}
                            {/* {`${watch("fullName").split(" ")[0]}`} */}
                            <span
                              className="hand-icon"
                              style={{ fontSize: "40px", marginLeft: "10px" }}
                            >
                              👋🏻
                            </span>{" "}
                            {!nameChange && (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setNameChange(true)}
                              >
                                {" "}
                                Not You?
                              </span>
                            )}
                          </h1>
                          <p>
                            We’ve fetched your vehicle details,{" "}
                            <span>Did we get it right?</span>
                          </p>
                        </div>

                        <div className="vehicle_modal-info mt-3">
                          <div className="d-flex gap-3">
                            <div className="vh-img">
                              <img
                                src={
                                  vehicleType === VEHICLE_TYPE.TWO_WHEELER
                                    ? "/assets/images/BIKE PNG (1).png"
                                    : "/assets/images/CAR PNG WHITE.png"
                                }
                                alt="Vehicle"
                              />
                            </div>
                            <div className="text-start">
                              <p className="vehicle-info">
                                {" "}
                                {formatRegistrationNumber(registrationNumber)}
                              </p>
                              <p className="vehicle-info-1">
                                {`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}{" "}
                                <br />
                                {` ${
                                  registrationDate &&
                                  new Date(registrationDate).getFullYear()
                                } | ${variantDetails?.fuelType}`}
                              </p>
                            </div>
                          </div>
                          <div className="text-center">
                            <h1
                              className="not-you"
                              style={{ cursor: "pointer" }}
                              onClick={handleNotKnownVehicleDetails}
                            >{`Not your ${
                              vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                                ? "Car"
                                : "Bike"
                            }?`}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 modal-right">
                      <h4
                        className="fs-3 Just-one"
                        style={{ marginBottom: "35px" }}
                      >
                        Just one detail to go!
                      </h4>
                      {/* <form
                      onSubmit={handleSubmit1(handleUserDetails)}
                      className="w-100"
                    > */}
                      <div className="form-info">
                        {/* <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="userDetailsInput did-floating-input"
                    name="FullName"
                    {...register1("FullName", {
                      required: "Field is required",
                    })}
                    placeholder="Enter Full Name"
                    maxLength={50}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\d/g, ""))
                    }
                  />
                  <p className="text-danger">{errors1?.FullName?.message}</p>
                </div> */}

                        {nameChange && (
                          <div className="did-floating-label-content">
                            <input
                              type="text"
                              className="did-floating-input fullName"
                              {...register1("fullName", {
                                required: "Field is required",
                                minLength: {
                                  value: 3,
                                  message: "Please enter at least 3 characters",
                                },
                              })}
                              maxLength={100}
                              onChange={(e) => {
                                clearErrors("fullName");
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z ]/g,
                                  ""
                                );
                                setNameValue(e?.target?.value);
                                setValue("fullName", e?.target?.value);
                              }}
                              id="Fname"
                              placeholder=" "
                            />
                            <label
                              htmlFor="Fname"
                              className="did-floating-label"
                            >
                              {`${
                                motorRequest?.customerType ===
                                CUSTOMER_TYPE?.INDIVIDUAL
                                  ? "Full Name"
                                  : "Organisation Name"
                              } `}
                              <span className="text-danger">*</span>
                            </label>
                            <p className="f-error text-danger">
                              {errors1?.fullName?.message}
                            </p>
                          </div>
                        )}

                        <div className="did-floating-label-content">
                          {" "}
                          <input
                            type="text"
                            className="did-floating-input fullName"
                            name="mobileNumber"
                            {...register1("mobileNumber", {
                              required: "Field is required",
                              pattern: {
                                // value:
                                //   /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                              },
                              minLength: {
                                value: 10,
                                message: "Invalid Mobile Number",
                              },
                            })}
                            placeholder=" Enter Mobile Number"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            onChange={(e) => {
                              let value = e.target.value.replace(/[^0-9]/g, "");
                              if (
                                value.length > 0 &&
                                (value.startsWith("0") ||
                                  value.startsWith("1") ||
                                  value.startsWith("2") ||
                                  value.startsWith("3") ||
                                  value.startsWith("4") ||
                                  value.startsWith("5"))
                              ) {
                                value = value.substring(1);
                              }
                              e.target.value = value;
                              clearErrors1("mobileNumber");
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            readOnly={isEditable ? false : true}
                            style={{
                              cursor: isEditable ? "text" : "not-allowed",
                            }}
                          />
                          {!isEditable && (
                            <HiMiniPencil
                              className="position-absolute"
                              style={{
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={handleEditClick}
                            />
                          )}
                          <label
                            htmlFor="number2"
                            className="did-floating-label"
                          >
                            Mobile No.
                            <span className="text-danger">*</span>
                          </label>
                          <p className="text-danger">
                            {errors1?.mobileNumber?.message}
                          </p>
                        </div>

                        <button
                          type="submit"
                          className="modalBtnSubmit mt-4  mb-2"
                        >
                          {submit
                            ? "Getting Quotes..."
                            : "Submit and View Plans"}
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {currentSteps === 2 && (
            <div className="modalBody">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-3">
                <h4 className="text-center">Fill your Personal Details?</h4>
                <div onClick={toggleModal} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="modalContent">
                <div className="leftContent">
                  <h5 className="vehicleNumber">
                    {registrationNumber}

                    {/* {rcName ? rcName : "RJ45SA9490"} */}
                  </h5>
                  {motorRequest?.vehicleType === "2w" ? (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  ) : (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  )}
                </div>
                <div className="rightContent">
                  <img
                    src={
                      vehicleType === "2w"
                        ? "/assets/images/BIKE PNG (1).png"
                        : "/assets/images/car-wash.png"
                    }
                  />
                </div>
              </div>
              <form
                onSubmit={handleSubmit1(handleUserDetails)}
                className="w-100"
              >
                <div>
                  {/* Insurer Dropdown */}
                  <div className="formStep8PolicyInsurer">
                    <div
                      className="did-floating-label-content"
                      style={{ marginBottom: "0px" }}
                    >
                      <select
                        name="insurerCode"
                        {...register("insurerCode", {
                          required: "Previous Policy Insurer is required",
                        })}
                        className="form-select formStep8PolicyInsurerSelect"
                        id="idxx3"
                        defaultValue={previousInsurerDetails?.value || ""}
                      >
                        <option className="d-none" value="">
                          Previous Policy Insurer
                        </option>
                        {insurerData &&
                          insurerData.map((item, i) => (
                            <option key={i} value={item.value}>
                              {item.option}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="idxx3" className="did-floating-label">
                        Select Previous Policy Insurer
                      </label>
                    </div>
                    <p className="f-error text-danger">
                      {errors?.insurerCode?.message}
                    </p>
                  </div>

                  {/* Previous Policy Expiry Date */}
                  {motorRequest?.isVehicleNew === false && (
                    <div className="did-floating-label-content">
                      <DatePicker
                        {...register("prevPolicyEndDate", {
                          required: "Previous Policy Expiry Date is required",
                        })}
                        selected={prevPolicyDate}
                        onChange={handlePrevPolicyEndDate}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              prevPolicyDate
                                ? moment(prevPolicyDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        name="prevPolicyEndDate"
                        id="idxx6"
                        className="did-floating-input"
                        minDate={
                          watch("businessType") ===
                          POLICY_STATUS.EXPIREDWITHIN90
                            ? moment().subtract(90, "days").toDate()
                            : moment().toDate()
                        }
                        maxDate={moment().add(180, "days").toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Previous Policy Expiry Date"
                      />
                      <label htmlFor="idxx6" className="did-floating-label">
                        Previous Policy Expiry Date
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error text-danger">
                        {errors?.prevPolicyEndDate?.message}
                      </p>
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <button type="submit" className="modalBtnSubmit mb-2">
                  {submit ? "Getting Quotes..." : "Submit and View Plans"}
                </button>
              </form>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VehicleDetailsModal;
