import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { submitProposal } from "../services/TPApiCall";
import {
  caseStatus,
  kycErrorMesssage,
  toastFailure,
} from "../utility/constants";
import { dispatchMotorQuote } from "../../store/action/userActions";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";

const PremiumMismatchModal = ({
  show,
  setModal,
  error,
  updatedQuote,
  setVehicleDetailsSpinner,
  vehicleDetailsSpinner,
  kycMode,
  setKycMode,
  setCurrentStep,
}) => {
  const toggle = () => setModal(!show);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const navigate = useNavigate();

  const handleUpdatedPremium = async () => {
    setVehicleDetailsSpinner(true);
    const newData = { ...selectedPlan };
    newData["FinalPremium"] = updatedQuote?.finalPremium;
    newData["NetPremium"] = updatedQuote?.netPremium;
    newData["GST"] = updatedQuote?.gst;
    try {
      console.log("proposal request data", newData);

      const response = await submitProposal(
        motorRequest,
        newData,
        motorRequest?.kycFields,
        userDetails
      );

      if (response?.data?.caseStatus === caseStatus?.proposalSuccess) {
        dispatchMotorQuote("paymentUrl", response?.data?.paymentUrl);
        toast.success("Proposal Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(`/checkout/${motorRequest?.customerLeadId}`);
        toggle();
        setVehicleDetailsSpinner(false);
      } else if (
        response?.data?.errors?.some(
          (error) => error?.errorMessage === kycErrorMesssage.invalidKyc
        )
      ) {
        setKycMode("ovd");
        dispatchMotorQuote("form60Link", motorRequest?.panFileLink);
        dispatchMotorQuote("form60Id", motorRequest?.panFileName);
        dispatchMotorQuote("kycType", "ovd");
        dispatchMotorQuote("ovdActive", true);
        if (motorRequest?.frontId) {
          dispatchMotorQuote("frontId", "");
        }
        if (motorRequest?.backId) {
          dispatchMotorQuote("backId", "");
        }
        toggle();
        setCurrentStep(1);
        toastFailure("CKYC Failed. Please proceed with OVD");
        return;
      } else {
        setVehicleDetailsSpinner(false);
        toast.error(`${response?.data.errors[0].displayMessage}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        toggle();
      }
    } catch (error) {
      console.log("Error submitting vehicle details:", error);
      setVehicleDetailsSpinner(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a href="">
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-4">Premium Mismatch</h4>

              <div
                onClick={toggle}
                class="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <a>
                  {/* <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  /> */}
                  <RxCross2 style={{ color: "#fff" }} />
                </a>
              </div>

              <div className="premium_mismatch mt-3">
                <h6>{error?.errorMessage}</h6>
              </div>
              <div className="d-flex justify-content-center gap-3 mt-3">
                <button className="mismatch-btn" onClick={handleUpdatedPremium}>
                  {vehicleDetailsSpinner ? (
                    <div class="spinner-border text-light" role="status"></div>
                  ) : (
                    `Accept ₹${Math.round(updatedQuote?.netPremium)}`
                  )}
                </button>
                <button
                  className="mismatch-btn"
                  onClick={() =>
                    navigate(`/quotelist/${motorRequest?.customerLeadId}`)
                  }
                >
                  Go to Quote Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PremiumMismatchModal;
