import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import "./Quotelist.css";
import TooltipCommon from "../common/TooltipCommon";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import {
  KotakAPICall,
  digitAPICall,
  processFlow,
  sompoAPICall,
} from "../services/TPApiCall";
import {
  Addons,
  POLICY_TYPE,
  TPAddons,
  VEHICLE_TYPE,
  accessoriesCover,
  accidentCovers,
  odAddons,
} from "../utility/constants";
import { RxCross2 } from "react-icons/rx";

function QuotelistAccessoriesCovers({
  show,
  setModel,
  handleFilteredResult,
  accessoriesFilter,
  setAccessoriesFilter,
}) {
  const dispatch = useDispatch();

  const toggle = () => setModel(!show);

  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  useEffect(() => {
    localStorage.setItem(
      "accessoriesFilter",
      JSON.stringify(accessoriesFilter)
    );
  }, [accessoriesFilter]);

  // Handle filter options

  const handleFilterOptions = (cover, item) => {
    dispatchQuickQuote("isFiltered", true);
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };

    for (let key in accessoriesFilter) {
      newStructureData[key] = accessoriesFilter[key];
      dispatchMotorQuote(`${key}`, accessoriesFilter[key]);
    }
    const postData = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      processDiscription: "quotation",
      motorRequest: { ...motorRequest },
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...newStructureData },
      },
    };
    processFlow(postData).then((response) => console.log("response", response));

    dispatch(resetQuickQuoteResults());

    // debugger;
    // sompoAPICall(apiRequestQQ);
    // KotakAPICall(apiRequestQQ);
    // digitAPICall(apiRequestQQ);

    handleFilteredResult(apiRequestQQ, newStructureData);
  };

  // const filterNew = () => {};

  const handleChangeFilter = (e) => {
    const { name, checked } = e.target;
    console.log("handleChangeFilter", name, checked);
    if (name === "isCngAccessories" && !checked) {
      setAccessoriesFilter({
        ...accessoriesFilter,
        [name]: checked,
        ["cngValue"]: 0,
      });
    } else if (name === "isElectricalAccessories" && !checked) {
      setAccessoriesFilter({
        ...accessoriesFilter,
        [name]: checked,
        ["electricAmount"]: 0,
      });
    } else if (name === "isNonElectricalAccessories" && !checked) {
      setAccessoriesFilter({
        ...accessoriesFilter,
        [name]: checked,
        ["nonElectricAmount"]: 0,
      });
    } else setAccessoriesFilter({ ...accessoriesFilter, [name]: checked });
  };

  const handleChnageValue = (e) => {
    const { name, value } = e.target;
    console.log("handleChnageValue", name, value);
    setAccessoriesFilter({ ...accessoriesFilter, [name]: parseFloat(value) });
  };

  const filteredAddons = (addons) => {
    const includedCovers = ["isCngAccessories"];
    if (motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY) {
      return addons.filter((item) => includedCovers.includes(item?.value));
    } else return addons;
  };

  const filteredTPAddons = filteredAddons(TPAddons);
  const filteredODAddons = filteredAddons(odAddons);
  const filteredAddonsList = filteredAddons(Addons);

  const addonsToRender = filteredAddons(accessoriesCover);

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a href="">
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-5">Accessories Cover</h4>

              <div
                onClick={toggle}
                class="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <a>
                  {/* <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  /> */}
                  <RxCross2 style={{ color: "#fff" }} />
                </a>
              </div>
            </div>

            <div className="mb-3">
              <div className="add-ons-outer px-1">
                {
                  // (apiRequestQQ.NewPolicyType === "ThirdParty"
                  //   ? TPAddons
                  //   : apiRequestQQ.NewPolicyType === "ODOnly"
                  //   ? odAddons
                  //   : Addons
                  // )
                  addonsToRender.map((item, index) => (
                    <div className="form-check mb-2 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={item.value}
                        style={{ border: "1px solid black" }}
                        name={item.value}
                        checked={accessoriesFilter[item.value]}
                        onChange={(e) => handleChangeFilter(e)}
                        id={item.value}
                      />
                      <label
                        className="form-check-lable d-block"
                        htmlFor={item.value}
                      >
                        {item.label}
                      </label>
                      {item.value !== "isPassengerCover" &&
                        item.value !== "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        accessoriesFilter[item.value] == true && (
                          <input
                            type="number"
                            min={item.min}
                            name={item.inputName}
                            value={accessoriesFilter[item.inputName]}
                            onChange={(e) => handleChnageValue(e, item.value)}
                            style={{ padding: "5px 12px", borderRadius: "6px" }}
                          />
                        )}
                      {item.value === "isPassengerCover" &&
                        item.type == "input" &&
                        accessoriesFilter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "205px",
                                border: "2px solid black",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={accessoriesFilter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                      {item.value === "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        accessoriesFilter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "205px",
                                border: "2px solid black",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={accessoriesFilter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                    </div>
                  ))
                }
              </div>
            </div>
            <div class="v2_btngrp">
              {/* <div class="wrapper appliedno">
                <div class="bold">
                  (
                  {
                    Object.values(accessoriesFilter).filter(
                      (value) => value === true
                    ).length
                  }
                  ) Applied
                </div>{" "}
                <div class="match">Matching with your criteria</div>
              </div> */}
              <button
                type="submit"
                name="submitBtn"
                class="button bold font-13"
                style={{ width: "30%" }}
                onClick={() => {
                  handleFilterOptions("", "");
                  toggle();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistAccessoriesCovers;
