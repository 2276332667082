import React, { useEffect, useState } from "react";
import BasicDateCalendar from "../common/BasicDateCalendar";
import { Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";

import { useMediaQuery, useTheme } from "@mui/material";
import { RxCross2 } from "react-icons/rx";

const PrevDateModal = ({
  dateModal,
  toggle,
  setNcbModel,
  dateModalOpenFromQuotelist,
  handleFilteredResult,
  policyDateExceeded,
  setPolicyDateExceeded,
}) => {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const [prevPolicyEndDate, setPrevPolicyEndDate] = useState(
    motorRequest?.prevPolicyEndDate
  );

  // useEffect(() => {
  //   debugger;
  //   console.log("prevPolicyEndDate", prevPolicyEndDate);
  //   if (prevPolicyEndDate) {
  //     toggle();
  //     setNcbModel(true);
  //   }
  // }, [prevPolicyEndDate]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal
      isOpen={dateModal}
      toggle={toggle}
      size="lg"
      centered
      backdrop="static"
      className={`calender-div d-flex justify-content-center ${
        isMobile ? "w-100" : ""
      }`}
    >
      <ModalBody className="p-0">
        <div class="p-2">
          <div class="gsc_modalWrapper">
            {/* <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div> */}
            <div className="modal_heading w-100 mb-3">
              {/* <h4 className="text-center fs-5">Confirm NCB</h4> */}

              {dateModalOpenFromQuotelist && (
                <div
                  onClick={toggle}
                  class="v2_close false"
                  style={{ cursor: "pointer" }}
                >
                  <a>
                    <RxCross2 style={{ color: "#fff" }} />
                  </a>
                </div>
              )}
            </div>
            <BasicDateCalendar
              prevPolicyEndDate={prevPolicyEndDate}
              setPrevPolicyEndDate={setPrevPolicyEndDate}
              toggle={toggle}
              setNcbModel={setNcbModel}
              dateModalOpenFromQuotelist={dateModalOpenFromQuotelist}
              handleFilteredResult={handleFilteredResult}
              policyDateExceeded={policyDateExceeded}
              setPolicyDateExceeded={setPolicyDateExceeded}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PrevDateModal;
