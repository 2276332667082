import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  apiErrorReponse,
  dispatchHealthQuickQuote,
  dispatchMotorQuote,
  dispatchProcessFlowHealth,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetMotorQuote,
  dispatchUserDetails,
  healthQuickQuoteResult,
  quickQuoteResult,
  resetHealthQuickQuoteResults,
  resetQuickQuoteResults,
  resetSelectedPlan,
  selectedPlanAction,
} from "../store/action/userActions";
import QuotelistModel from "./QuotelistComponents/QuotelistModel";
import QuotelistModelPlan from "./QuotelistComponents/QuotelistModelPlan";
import QuotelistDropdown from "./QuotelistComponents/QuotelistDropdown";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import QuotelistAddOns from "./QuotelistComponents/QuotelistAddOns";
import "./QuotelistComponents/Quotelist.css";
import { IoMdArrowDropdown, IoMdShare } from "react-icons/io";
// import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

import Layout from "./common/Layout";
import Logo from "./download.png";
import { IoIosArrowForward } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import QuotelistModelPlanHealth from "./QuotelistComponents/QuotelistModelHealth";
import QuotelistAllFilter from "./QuotelistComponents/QuotelistAllFilter";
import { useForm } from "react-hook-form";
import {
  KotakAPICall,
  createCustomerHealth,
  createVehicleDetailsSave,
  digitAPICall,
  getMotorQuote,
  getPreviousJourneyDetails,
  getQuotationNumber,
  handleQuotesData,
  kotakIssueApi,
  processFlow,
  sompoAPICall,
} from "./services/TPApiCall";
import store from "../store";
import QuotelistCashlessHospitalList from "./QuotelistComponents/QuotelistCashlessHospitalList";
import Skeleton from "./Skeleton/Skeleton";
import { FaPencil } from "react-icons/fa6";
import EditDetailsModal from "./QuotelistComponents/EditDetailsModal";
import PremiumBreakup from "./QuotelistComponents/PremiumBreakup";
import { failureCaseArr, policyResultArr } from "./common/MakeModelArr";
import {
  Addons,
  POLICY_STATUS,
  POLICY_TYPE,
  TPAddons,
  VEHICLE_TYPE,
  accessoriesCover,
  accidentCovers,
  addOns,
  extractCustomerLeadId,
  insurers,
  moveToTop,
  source,
} from "./utility/constants";
import { useCookies } from "react-cookie";
import { isArray } from "lodash";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MdMoreHoriz } from "react-icons/md";
import SharePaymentQuotationModal from "./common/SharePaymentQuotationModal";
import PrevAddonsModal from "./QuotelistComponents/PrevAddonsModal";
import { PDFDocument } from "pdf-lib";
import BasicDateCalendar from "./common/BasicDateCalendar";
import PrevDateModal from "./QuotelistComponents/PrevDateModal";
import QuotelistAccidentCovers from "./QuotelistComponents/QuotelistAccidentCovers";
import QuotelistAccessoriesCovers from "./QuotelistComponents/QuotelistAccessoriesCovers";
import { HiMiniPencil } from "react-icons/hi2";
import PolicyDateError from "./PolicyDateError/PolicyDateError";

const Quotelist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  const [idv, setIdv] = useState(0);
  const [minIdv, setMinIdv] = useState(0);
  const [ncbModel, setNcbModel] = useState(false);
  const [planModel, setPlanModel] = useState(false);
  const [addCover, setAddCover] = useState(false);
  const [accidentCoverModal, setAccidentCoverModal] = useState(false);
  const [accessoriesCoverModal, setAccessoriesCoverModal] = useState(false);
  const [minMaxIdv, setMinMaxIdv] = useState(null);
  const [cashlessHospitalList, setCashlessHospitalList] = useState(false);
  const [cashlessHospitals, setCashlessHospitals] = useState([]);
  const [planDropdown, setPlanDropdown] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [premiumBreakup, setPremiumBreakup] = useState(false);
  const [premiumBreakupModal, setPremiumBreakupModal] = useState(false);
  const [planDetail, setPlanDetail] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [filterLIST, setFilterLIST] = useState([]);
  const [zeroDepreciationCover, setZeroDepreciationCover] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [differenceYears, setDifferenceYear] = useState(0);
  const [initialCall, setInitialCall] = useState(false);
  const [dataMotorQuotation, setDataMotorQuotation] = useState({});
  const [noPlans, setNoPlans] = useState(false);

  const HealthQuickQuoteResult = useSelector(
    (state) => state.root.HealthQuickQuoteResult
  );
  const [getMotorQuoteResponse, setGetMotorQuoteResponse] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);
  const [sharePaymentModal, setSharePaymentModal] = useState(false);
  const toggleShareQuotePaymentModal = () =>
    setSharePaymentModal(!sharePaymentModal);

  const [prevAddonsModal, setPrevAddonsModal] = useState(false);
  const togglePrevAddonsModal = () => setPrevAddonsModal(!prevAddonsModal);

  const [paCoverModal, setPACoverModal] = useState(false);
  const togglePACoverModal = () => setPACoverModal(!paCoverModal);

  const [addonsQuesAsk, setAddonsQuesAsk] = useState([]);
  const [policyDateExceeded, setPolicyDateExceeded] = useState(
    motorRequest?.policyDateExceeded ? motorRequest?.policyDateExceeded : false
  );

  const userDetails = useSelector((state) => state?.root?.userDetails);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const [addOnfilter, setAddOnfilter] = useState(() => {
    const storedAddOnFilter = localStorage.getItem("addOnfilter");
    return storedAddOnFilter
      ? JSON.parse(storedAddOnFilter)
      : {
          zeroDepriciationCover: false,
          roadSideAssistance: false,
          consumables: false,
          keyReplacement: false,
          invoiceCover: false,
          ncbProtection: false,
          lossOfPersonalBelongings: false,
          engineProtection: false,
          tyreProtection: false,
          isTppdDiscount: false,
        };
  });
  const [accessoriesFilter, setAccessoriesFilter] = useState(() => {
    const storedAccessoriesFilter = localStorage.getItem("accessoriesFilter");
    return storedAccessoriesFilter
      ? JSON.parse(storedAccessoriesFilter)
      : {
          isElectricalAccessories: false,
          isNonElectricalAccessories: false,
          isCngAccessories: false,
          electricAmount: 0,
          nonElectricAmount: 0,
          cngValue: 0,
        };
  });
  const [accidentFilter, setAccidentFilter] = useState(() => {
    const storedAccidentFilter = localStorage.getItem("accidentfilter");
    return storedAccidentFilter
      ? JSON.parse(storedAccidentFilter)
      : {
          personalAccident: false,
          isPassengerCover: false,
          liabilitiesToPaidDriver: false,
          passengerCoverAmount: 0,
          noOfPaidDrivers: 0,
        };
  });

  const [dateModal, setDateModal] = useState(false);
  const [dateModalOpenFromQuotelist, setDateModalOpenFromQuotelist] =
    useState(false);
  const toggleDateModal = () => setDateModal(!dateModal);

  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );

  const ApiErrorArr = useSelector((state) => state?.root?.ApiErrorArr);
  const healthRequestQQ = useSelector((state) => state.root.healthRequestQQ);

  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);

  const location = useLocation();
  console.log("location---", location);
  let noPlanToShow = location?.state?.policyType?.noPlanToShow;

  // if (location?.state?.journeyContinue) {
  //   dispatchQuickQuote("NCBSET", false);
  //   dispatch(resetQuickQuoteResults());
  // }
  // useEffect(() => {
  //   debugger;
  //   if (location?.state?.journeyContinue) {
  //     dispatchQuickQuote("NCBSET", false);
  //     dispatch(resetQuickQuoteResults());
  //   }
  // }, []);

  const updateVahaanData = async () => {
    const response = await createVehicleDetailsSave(motorRequest, "");
    console.log("createVehicleDetailsSave response quotelist", response);
  };

  useEffect(() => {
    const url = extractCustomerLeadId(location?.pathname);
    console.log("uurrrlllll", url);
    if (
      !motorRequest?.handlePreviousButton ||
      QuickQouteResult.length === 0 ||
      motorRequest?.customerLeadId !== url
    ) {
      console.log("location?.state", location?.state);
      if (location?.state?.vahaanJourney) {
        if (noPlanToShow) {
          const newData = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            step: "step7",
            motorRequest: { ...motorRequest },
            processDiscription: "quotation",
            step7: {
              ...processFlowObjMotor.step7,
              motorRequest: { ...motorRequest },
            },
          };
          processFlow(newData);
          return;
        } else {
          setDateModal(true);
          updateVahaanData();
          if (!motorRequest?.rtoCode || !motorRequest?.vehicleType) {
            fetchPreviousJourneyDetails();
          }
          return;
        }
      } else {
        fetchPreviousJourneyDetails();
      }
    }
  }, []);

  useEffect(() => {
    if (
      motorRequest?.vehicleType &&
      HealthQuickQuoteResult.length === 0 &&
      QuickQouteResult.length > 0 &&
      !motorRequest?.handlePreviousButton
    ) {
      dispatchProcessFlowMotor("step", "step7");
      const newData = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: "step7",
        motorRequest: { ...motorRequest },
        processDiscription: "quotation",
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...motorRequest },
          QuickQouteResult: { ...QuickQouteResult },
        },
      };

      if (!cookies.customerLeadId) {
        const now = new Date();
        // Set the cookie to expire in 2 hours
        const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
        setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
      }
      processFlow(newData);
    }

    QuickQouteResult &&
      QuickQouteResult.map((quote) => {
        if (
          // quote?.Api_name ===
          //   "Future Generali India Insurance Company Limited" &&
          quote?.isAddonQuesAsk
        ) {
          setAddonsQuesAsk(quote?.addonQuesArray);
        }
      });

    // moveToTop();
  }, [QuickQouteResult]);



  const fetchPreviousJourneyDetails = async () => {
    let newDataForMotorRequest = { ...motorRequest };
    try {
      const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);
      if (urlCustomerLeadId !== motorRequest?.customerLeadId) {
        dispatchResetMotorQuote();
        dispatch(resetQuickQuoteResults());
        dispatch(resetSelectedPlan());
      }
      dispatchMotorQuote("customerLeadId", urlCustomerLeadId);
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", urlCustomerLeadId, expires);

      const response = await getPreviousJourneyDetails({
        customerLeadId: urlCustomerLeadId,
      });

      const stepsData = response?.data?.stepsData;
      if (
        response?.data?.processStep === "step13" &&
        response?.data?.processDiscription === "pdfGeneration"
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId;
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key]);
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
      } else if (
        (response?.data?.processStep === "step7" &&
          response?.data?.processDiscription === "quotation") ||
        (response?.data?.processStep === "step6" &&
          response?.data?.processDiscription === "pypSelection")
      ) {
        try {
          if (stepsData?.step7?.motorRequest) {
            for (let key in stepsData?.step7?.motorRequest) {
              dispatchMotorQuote(`${key}`, stepsData?.step7?.motorRequest[key]);
              newDataForMotorRequest[key] = stepsData?.step7?.motorRequest[key];
            }
            if (stepsData?.step7?.motorRequest?.policyDateExceeded) {
              setPolicyDateExceeded(true);
              return;
            }
          } else {
            for (let key in stepsData?.step6?.motorRequest) {
              dispatchMotorQuote(`${key}`, stepsData?.step6?.motorRequest[key]);
              newDataForMotorRequest[key] = stepsData?.step6?.motorRequest[key];
            }

            for (let key in stepsData?.step6?.pypSelection) {
              dispatchMotorQuote(`${key}`, stepsData?.step6?.pypSelection[key]);
              newDataForMotorRequest[key] = stepsData?.step6?.pypSelection[key];
            }
          }

          const updatedAddonFilter = { ...addOnfilter };

          addOns.forEach((addon) => {
            const motorValue = stepsData?.step7?.motorRequest[addon.value];
            const motorValueAmount =
              stepsData?.step7?.motorRequest[addon.inputName];
            if (
              motorValue !== undefined &&
              motorValue !== addOnfilter[addon.value]
            ) {
              updatedAddonFilter[addon.value] = motorValue;
              if (addon.type === "input" && addon.inputName) {
                updatedAddonFilter[addon.inputName] = motorValueAmount;
              }
            }
          });

          setAddOnfilter(updatedAddonFilter);
          localStorage.setItem(
            "addOnfilter",
            JSON.stringify(updatedAddonFilter)
          );

          const updatedAccessoriesFilter = { ...accessoriesFilter };

          accessoriesCover.forEach((addon) => {
            const motorValue = stepsData?.step7?.motorRequest[addon.value];
            const motorValueAmount =
              stepsData?.step7?.motorRequest[addon.inputName];
            if (
              motorValue !== undefined &&
              motorValue !== accessoriesFilter[addon.value]
            ) {
              updatedAccessoriesFilter[addon.value] = motorValue;
              if (addon.type === "input" && addon.inputName) {
                updatedAccessoriesFilter[addon.inputName] = motorValueAmount;
              }
            }
          });

          setAccessoriesFilter(updatedAccessoriesFilter);
          localStorage.setItem(
            "accessoriesFilter",
            JSON.stringify(updatedAccessoriesFilter)
          );

          const updatedAccidentFilter = { ...accidentFilter };

          accidentCovers.forEach((addon) => {
            const motorValue = stepsData?.step7?.motorRequest[addon.value];
            const motorValueAmount =
              stepsData?.step7?.motorRequest[addon.inputName];
            if (
              motorValue !== undefined &&
              motorValue !== accidentFilter[addon.value]
            ) {
              updatedAccidentFilter[addon.value] = motorValue;
              if (addon.type === "input" && addon.inputName) {
                updatedAccidentFilter[addon.inputName] = motorValueAmount;
              }
            }
          });

          setAccidentFilter(updatedAccidentFilter);
          localStorage.setItem(
            "accidentFilter",
            JSON.stringify(updatedAccidentFilter)
          );

          for (let key in stepsData?.step7?.previousClaimData) {
            dispatchMotorQuote(
              `${key}`,
              stepsData?.step7?.previousClaimData[key]
            );
            newDataForMotorRequest[key] =
              stepsData?.step7?.previousClaimData[key];
          }
          // dispatch(resetQuickQuoteResults());
          if (
            (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
              motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90) &&
            !motorRequest?.isVehicleNew &&
            stepsData?.step6?.pypSelection?.newPolicyType !==
              POLICY_TYPE.THIRDPARTY &&
            !stepsData?.step5?.isOwnerChanged &&
            stepsData?.step6?.pypSelection?.businessType !==
              POLICY_STATUS.NOTEXPIRED &&
            motorRequest?.businessType !== "new" &&
            !motorRequest?.isVehicleNew
          ) {
            if (location?.state?.vahaanJourney) {
              setDateModal(true);
              return;
            } else {
              motorRequest?.newPolicyType !== "noPlans" && setNcbModel(true);
            }
          }
          // navigate(`/quotelist/${previousProcessJourney?.customerLeadId}`);
        } catch (error) {
          console.error("Error processing motor request details:", error);
        }
      } else if (
        (response?.data?.processStep === "step8" &&
          response?.data?.processDiscription === "kycAndUserDetails") ||
        (response?.data?.processStep === "step9" &&
          response?.data?.processDiscription === "nomineeDetails") ||
        (response?.data?.processStep === "step10" &&
          response?.data?.processDiscription === "vehicleDetails")
      ) {
        navigate(`/proposal/${urlCustomerLeadId}`);
        updateJourneyData(
          stepsData,
          response?.data?.processStep,
          response?.data?.processDiscription
        );
        return;
      }
      // else if (
      //   (response?.data?.processStep === "step9" &&
      //     response?.data?.processDiscription === "nomineeDetails") ||
      //   (response?.data?.processStep === "step10" &&
      //     response?.data?.processDiscription === "vehicleDetails") ||
      //   (response?.data?.processStep === "step8" &&
      //     response?.data?.processDiscription === "kycAndUserDetails")
      // ) {
      //   navigate(`/proposal/${response?.data?.customerLeadId}`);
      // }
    } catch (error) {
      console.error("Error fetching previous journey details:", error);
    }

    if (
      motorRequest?.isVehicleNew === false &&
      motorRequest?.vehicleType &&
      HealthQuickQuoteResult.length === 0 &&
      motorRequest?.newPolicyType !== POLICY_TYPE?.THIRDPARTY &&
      !motorRequest?.isOwnerChanged &&
      (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
        motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90)
    ) {
      if (location?.state?.vahaanJourney) {
        setDateModal(true);
        return;
      } else {
        motorRequest?.newPolicyType !== "noPlans" && setNcbModel(true);
      }
    }

    if (
      (motorRequest?.businessType !== POLICY_STATUS.NOTEXPIRED &&
        motorRequest?.businessType !== POLICY_STATUS.EXPIREDWITHIN90) ||
      motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY
      // || motorRequest?.isOwnerChanged
    ) {
      console.log("newDataForMotorRequest", newDataForMotorRequest);
      newDataForMotorRequest && motorQuotation(newDataForMotorRequest);
    }

    const storedAddOnFilter = localStorage.getItem("addOnfilter");
    if (storedAddOnFilter) {
      setAddOnfilter(JSON.parse(storedAddOnFilter));
    }
    const storedAccessoriesFilter = localStorage.getItem("accessoriesFilter");
    if (storedAccessoriesFilter) {
      setAccessoriesFilter(JSON.parse(storedAccessoriesFilter));
    }
    const storedAccidentFilter = localStorage.getItem("accidentFilter");
    if (storedAccidentFilter) {
      setAccidentFilter(JSON.parse(storedAccidentFilter));
    }
  };

  useEffect(() => {
    localStorage.setItem("addOnfilter", JSON.stringify(addOnfilter));
    localStorage.setItem(
      "accessoriesFilter",
      JSON.stringify(accessoriesFilter)
    );
    localStorage.setItem("accidentFilter", JSON.stringify(accidentFilter));
  }, [addOnfilter, accessoriesFilter, accidentFilter]);

  useEffect(() => {
    let today = new Date();
    let inputValue = new Date(motorRequest?.registrationDate);
    let difference = today - inputValue;

    let differenceInYears = parseFloat(
      (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(3)
    );
    setDifferenceYear(differenceInYears);
    dispatchQuickQuote("differenceInYears", differenceInYears);
    dispatchMotorQuote("differenceInYears", differenceInYears);
  }, [motorRequest?.registrationDate]);

  useEffect(() => {
    filterPlanTypeList();
  }, [motorRequest?.registrationDate]);

  useEffect(() => {
    if (HealthQuickQuoteResult.length > 0) {
      dispatchProcessFlowHealth("step4", HealthQuickQuoteResult);
      const newData = {
        ...processFlowObjHealth,
        step: "step4",
        step4: { healthQuotes: { ...HealthQuickQuoteResult } },
      };
      processFlow(newData);
    }
  }, [HealthQuickQuoteResult]);

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    try {
      console.log("stepsData?.step8?.kycConfig", stepsData?.step8?.kycConfig);
      dispatchKycConfig(stepsData?.step8?.kycConfig);
      dispatchProcessFlowMotor("step8", stepsData?.step8);

      for (let key in stepsData?.step8?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.motorRequest[key]);
      }

      for (let key in stepsData?.step8?.kycAndUserDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key]);
      }

      dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
      dispatchKycConfig(stepsData?.step8?.kycConfig);

      if (stepsData?.step8?.kycAndUserDetails?.addressLine1) {
        if (stepsData.step8.planDetails.PersonalAccident) {
          if (
            processStep === "step9" &&
            processDiscription === "nomineeDetails"
          ) {
            for (let key in stepsData?.step9?.motorRequest) {
              if (stepsData?.step9?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.motorRequest[key]
                );
              }
            }
            for (let key in stepsData?.step9?.nomineeDetails) {
              if (stepsData?.step9?.nomineeDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.nomineeDetails[key]
                );
              }
            }
            setCurrentStep(3);
          } else if (
            processStep === "step10" &&
            processDiscription === "vehicleDetails"
          ) {
            for (let key in stepsData?.step10?.motorRequest) {
              if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.motorRequest[key]
                );
              }
            }
            for (let key in stepsData?.step10?.vehicleDetails) {
              if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.vehicleDetails[key]
                );
              }
            }
            setCurrentStep(3);
          } else {
            setCurrentStep(2);
          }
        } else if (
          processStep === "step10" &&
          processDiscription === "vehicleDetails"
        ) {
          for (let key in stepsData?.step10?.motorRequest) {
            if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step10?.motorRequest[key]
              );
            }
          }
          for (let key in stepsData?.step10?.vehicleDetails) {
            if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step10?.vehicleDetails[key]
              );
            }
          }
          setCurrentStep(3);
        } else {
          setCurrentStep(3);
        }
      } else if (
        processStep === "step13" &&
        processDiscription === "pdfGeneration"
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId;
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key]);
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
      }
    } catch (error) {
      console.error("Error fetching previous journey details:", error);
    }
  };

  const submitUserPlan = (item) => {
    // debugger;
    const plan =
      item?.Company === "Universal Sompo General Health Insurance"
        ? "Sompo"
        : item?.Company === "care Health"
        ? "Care"
        : "";
    setCompanyName(plan);
    const newData = { ...item, planType: item?.planType };

    if (item?.isAddonQuesAsk) {
      // setAddonsQuesAsk(item?.addonQuesArray);
      if (motorRequest?.updatedFutureQuotes) {
        handlePremium(item);
      } else {
        togglePrevAddonsModal();
      }
    }
    console.log("item", item);

    dispatch(selectedPlanAction(newData));
    dispatchProcessFlowMotor("step8.planDetails", newData);
    dispatchQuickQuote("ApiId", item?.ApiId);
    dispatchQuickQuote("PaymentId", item?.ApiId);
    // if (item?.Api_name == "digit") {
    //   dispatchQuickQuote("ApiId", item?.ApiId);
    //   dispatchQuickQuote("Idv", item?.idv);
    // }
    // } else {
    //   dispatchQuickQuote("Idv", item?.idv);
    // }
    // if (item.Api_name === "Kotak") {
    //   // if (apiRequestQQ.NewPolicyType == "ODOnly") {
    //   //   return toggleODModal();
    //   // } else {
    //   dispatchQuickQuote("ApiId", item?.ApiId);
    //   dispatchQuickQuote("PaymentId", item?.PolicyId);
    //   // }
    // }
    // if (item?.Api_name === "Future") {
    //   dispatchQuickQuote("AddOns." + "discount", item?.discount);
    //   dispatchQuickQuote("Idv", item?.idv);
    // } else if (item?.Api_name === "Royal") {
    //   dispatchQuickQuote("Idv", item?.idv);
    //   dispatchQuickQuote("ApiId", item?.ApiId);
    //   dispatchQuickQuote("PaymentAmount", item?.FinalPremium);
    // }
    dispatchQuickQuote("PolicyStartDate", item?.StartDate);
    dispatchQuickQuote("PolicyEndDate", item?.EndDate);

    HealthQuickQuoteResult.length > 0
      ? navigate(`/health-proposal/${plan}/${healthRequestQQ?.enquiryId}`, {
          state: plan,
        })
      : handlePremium(item);
  };

  const [coverModel, setCoverModel] = useState(false);
  const [sortModel, setSortModel] = useState(false);
  const [insurer, setInsurerModel] = useState(false);
  const [planTypeModel, setPlanTypeModel] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [healthFilterSelect, setHealthFilterSelect] = useState([]);
  const [healthFilter, setHealthFilter] = useState({
    coverageNonMedical: false,
    preExistingDiseaseWaitingPeriod: false,
    maternityChildcareBenefit: false,
  });
  const policyTypeList = [
    // {
    //   type: "All",
    //   desc: "",
    // },
    {
      type: "Comprehensive",
      value: "comprehensive",
      // desc: "This covers your car (around 50%) and any damage to any third party.",
    },
    {
      type: "Third Party",
      value: "thirdParty",
      // desc: "This covers only accidental damage to any property or any other person but does not cover damage to your car.",
    },
    {
      type: "Own Damage",
      value: "odOnly",
      // desc: "100% coverage to your car, and does not depreciate the value of the car excluding consumables like engine oil,nuts, etc",
    },
  ];
  const [rId, setrId] = useState(null);
  const [motorData, setMotorData] = useState(null);

  const currentYear = new Date().getFullYear();
  // const filterPlanType = (list, registrationYear) => {
  //   if (
  //     apiRequestQQ?.VehicleType === "4w" &&
  //     currentYear - registrationYear > 5
  //   ) {
  //     return policyTypeList.filter(
  //       (item) => item.value === "Comprehensive" || item.value === "ThirdParty"
  //     );
  //   } else {
  //     return list;
  //   }
  // };

  const filterPlanTypeList = () => {
    let today = new Date();
    today.setDate(today.getDate() + 60);
    let inputValue = new Date(motorRequest?.registrationDate);

    let difference = today - inputValue;

    let differenceInYears = (
      difference /
      (1000 * 60 * 60 * 24 * 365.25)
    ).toFixed(4);

    if (
      (motorRequest?.vehicleType === "4w" && differenceInYears > 2.5) ||
      (motorRequest?.vehicleType === "2w" && differenceInYears > 4.5) ||
      motorRequest?.isVehicleNew
    ) {
      const filteredList = policyTypeList.filter((item) => {
        return !(item.type === "Own Damage");
      });
      setFilterLIST(filteredList);
    } else if (
      (motorRequest?.vehicleType === "4w" &&
        differenceInYears >= 2 &&
        differenceInYears <= 2.5) ||
      (motorRequest?.vehicleType === "2w" &&
        differenceInYears > 4 &&
        differenceInYears <= 4.5)
    ) {
      const filteredList = policyTypeList.filter((item) => {
        return !(item.type === "Third Party");
      });
      setFilterLIST(filteredList);
    } else {
      const filteredList = policyTypeList.filter((item) => {
        return item.type === "Own Damage";
      });
      setFilterLIST(filteredList);
    }
  };

  // const filteredList = policyTypeList.filter((item) => {
  //   // Filter the list based on the condition (registration year > 5 years)
  //   {
  //     return !(item.type === "Own Damage");
  //   }
  // });

  useEffect(() => {
    filterPlanTypeList();
  }, [planDetail]);

  const handlePremium = (item) => {
    // debugger;
    setPlanDetail(item);
    dispatchMotorQuote("planDetail", item);
    if (
      // item?.insurerId === insurers.Future.insurerId &&
      item?.isAddonQuesAsk &&
      !motorRequest?.updatedFutureQuotes
    ) {
      return;
    } else {
      setPremiumBreakup(true);
    }
  };

  const handelHealthFilterSelect = async (key) => {
    // if (healthFilterSelect?.includes(value)) {
    //   setHealthFilterSelect((prev) => {
    //     return prev.filter((res) => {
    //       return res !== value;
    //     });
    //   });
    // } else {
    //   setHealthFilterSelect((prev) => {
    //     return [...prev, value];
    //   });
    // }

    setHealthFilter((prev) => {
      const updatedAddons = {
        [key]: !prev[key],
      };

      dispatchHealthQuickQuote(`PlanDetails.addons.${key}`, updatedAddons[key]);
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  const fetchFilters = async () => {
    if (healthRequestQQ?.CustomerDetails?.customerFirstName !== "") {
      dispatch(resetHealthQuickQuoteResults());
      createCustomerHealth(healthRequestQQ);
      // .then((response) => {
      //   if (response?.success === true) {
      //     let healthQuoteResults = response?.data?.successQuotes;
      //     store.dispatch(healthQuickQuoteResult(healthQuoteResults));
      //   }
      // });
      // const createCustomer = await createCustomerHealth(healthRequestQQ);
      // let healthQuoteResults = createCustomer?.data?.successQuotes;
      // console.log("healthQuoteResults", healthQuoteResults);
      // store.dispatch(healthQuickQuoteResult(healthQuoteResults));
    }
  };

  useEffect(() => {
    fetchFilters();
  }, [healthFilter]);

  useEffect(() => {
    const idvValues = QuickQouteResult.map((item) => item.idv);
    const minimumIdv = Math.min(...idvValues);
    const maximumIdv = Math.max(...idvValues);
    setMinIdv(minimumIdv);
  }, [QuickQouteResult]);

  const submitHealthPlan = () => {
    navigate("/health-proposal/:enquiryId");
  };

  const submitMotorPlan = () => {
    navigate("/proposal");
  };

  const checkFilters = () => {
    dispatchQuickQuote("AddOns.ZeroDepriciationCover", !zeroDepreciationCover);
    const newData = {
      ...apiRequestQQ,
      [apiRequestQQ.AddOns.ZeroDepriciationCover]: zeroDepreciationCover,
    };

    dispatch(resetQuickQuoteResults());
    // sompoAPICall(newData);
    // digitAPICall(newData);
    // KotakAPICall(newData);
  };

  const handleFilteredResult = async (data, newStructureData) => {
    setMotorData(newStructureData);
    if (newStructureData?.idv > 0) {
      newStructureData = {
        ...newStructureData,
        sessionId:
          getMotorQuoteResponse?.sessionId || newStructureData?.sessionId,
      };
      console.log("getMotorQuoteResponse", getMotorQuoteResponse);
      // getMotorQuoteResponse &&
      //   dispatchMotorQuote("sessionId", getMotorQuoteResponse?.sessionId);
    } else {
      if (newStructureData) {
        newStructureData = {
          ...newStructureData,
          sessionId: "",
        };
        dispatchMotorQuote("sessionId", "");
      }
    }
    // console.log("handleFilteredResultData", data);
    // console.log("handleFilteredResultData", newStructureData);
    // console.log("minMaxIdv", minMaxIdv);
    // const insurerList = ["digit", "Kotak"];
    // insurerList.map((insurer) => {
    //   let newIdv = data?.Idv;
    //   let newData = { ...data };
    //   if (insurer === "digit") {
    //     if (newIdv < apiRequestQQ?.initialIdvRange?.Digit?.min) {
    //       newIdv = parseInt(
    //         Math.ceil(apiRequestQQ?.initialIdvRange?.Digit?.min)
    //       );
    //       newData = { ...newData, Idv: newIdv };
    //       // digitAPICall(newData);
    //     } else if (newIdv > apiRequestQQ?.initialIdvRange?.Digit?.max) {
    //       newIdv = parseInt(
    //         Math.floor(apiRequestQQ?.initialIdvRange?.Digit?.max)
    //       );
    //       newData = { ...newData, Idv: newIdv };
    //       // digitAPICall(newData);
    //     } else {
    //       newData = { ...newData, Idv: newIdv };
    //       // digitAPICall(newData);
    //     }
    //   } else if (insurer === "Kotak") {
    //     if (newIdv < apiRequestQQ?.initialIdvRange?.Kotak?.min) {
    //       newIdv = parseInt(
    //         Math.ceil(apiRequestQQ?.initialIdvRange?.Kotak?.min)
    //       );
    //       newData = { ...newData, Idv: newIdv };
    //       KotakAPICall(newData);
    //     } else if (newIdv > apiRequestQQ?.initialIdvRange?.Kotak?.max) {
    //       newIdv = parseInt(
    //         Math.floor(apiRequestQQ?.initialIdvRange?.Kotak?.max)
    //       );
    //       newData = { ...newData, Idv: newIdv };
    //       KotakAPICall(newData);
    //     } else {
    //       newData = { ...newData, Idv: newIdv };
    //       KotakAPICall(newData);
    //     }
    //   }
    // });
    console.log("newStructureData", newStructureData);
    newStructureData && motorQuotation(newStructureData);
    setInitialCall(true);
    // setGetMotorQuoteResponse(response);
    // console.log("resoince", response);
  };

  const motorQuotation = async (data) => {
    dispatch(resetQuickQuoteResults());
    if (data) {
      let response;
      let isEqual;
      let newData = { ...data };
      for (let key in data) {
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false;
          break;
        } else {
          isEqual = true;
        }
      }
      if (!isEqual) {
        dispatchMotorQuote("rId", "");
        newData = { ...newData, rId: "" };
      }
      setDataMotorQuotation(newData);
      let updatedData = { ...newData };
      let executionCount = 0;
      const fetchQuotesData = async () => {
        executionCount++;
        try {
          if (
            updatedData?.rId &&
            response?.caseStatus === "Quotes Generated" &&
            response?.isAllQuotesDone
          ) {
            // response = await getMotorQuote(updatedData);
            setGetMotorQuoteResponse(response);
            // if (executionCount >= 5) {
            handleQuotesData(response);
            clearInterval(interval);
            // moveToTop();
            // }
            if (response?.errors.length > 0) {
              if (response?.quotes.length > 0) {
                setGetMotorQuoteResponse(response);
                handleQuotesData(response);
              }

              response?.errors.map((error) =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.logo,
                      error?.displayMessage,
                      error?.insurerId
                    )
                  )
                )
              );
              // store.dispatch(apiErrorReponse(failureCaseArr(response.errors)));
              clearInterval(interval);
            }
            // if (response?.quotes?.length > 0) {
            //   if (executionCount >= 12) clearInterval(interval);
            //   console.log("executionCount", executionCount);
            //   response = await getMotorQuote(updatedData);
            //   setGetMotorQuoteResponse(response);
            //   handleQuotesData(response);
            // } else {
            //   response = await getMotorQuote(updatedData);
            // }
          } else if (
            updatedData?.rId &&
            response?.caseStatus === "Quotes Requested"
          ) {
            response = await getMotorQuote(updatedData, userDetails);
            setGetMotorQuoteResponse(response);
          } else if (response?.caseStatus === "Quotes In Process") {
            if (response?.quotes?.length > 0) {
              if (executionCount >= 50) clearInterval(interval);
              response = await getMotorQuote(updatedData, userDetails);
              setGetMotorQuoteResponse(response);
              handleQuotesData(response);
            } else {
              response = await getMotorQuote(updatedData, userDetails);
              if (executionCount >= 50) clearInterval(interval);
            }
          } else {
            if (executionCount >= 50) {
              clearInterval(interval);
            } else {
              response = await getMotorQuote(updatedData, userDetails);
            }
            if (
              response?.status === "failure" &&
              response?.errors?.length > 0
            ) {
              response?.errors.map((error) =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.logo,
                      error?.displayMessage,
                      error?.insurerId
                    )
                  )
                )
              );
              clearInterval(interval);
              throw new Error("API Error occurred");
            }
            setGetMotorQuoteResponse(response);
            updatedData = { ...updatedData, rId: response?.rId };
            dispatchMotorQuote("rId", response?.rId);
          }
        } catch (error) {
          console.error("Error occurred:", error);
          clearInterval(interval);
        }
      };

      const interval = setInterval(fetchQuotesData, 500);
    }
  };

  const compareObjects = (prevObj, newObj) => {
    // debugger;
    for (let key in prevObj) {
      if (prevObj[key] !== newObj[key]) {
        return false;
      }
    }
    return true;
  };

  const handleQuotesData = (data) => {
    dispatchMotorQuote("caseId", data?.caseId);
    dispatchMotorQuote("sessionId", data?.sessionId);
    data?.quotes?.map((item) =>
      store.dispatch(
        quickQuoteResult(
          policyResultArr(
            item?.insurerId === insurers.Digit.insurerId
              ? insurers.Digit.logo
              : item?.insurerId === insurers.Future.insurerId
              ? insurers.Future.logo
              : item?.insurerId === insurers.Bajaj.insurerId
              ? insurers.Bajaj.logo
              : item?.insurerId === insurers.Kotak.insurerId
              ? insurers.Kotak.logo
              : item?.insurerId === insurers.Sompo.insurerId
              ? insurers.Sompo.logo
              : item?.insurerId === insurers.Magma.insurerId
              ? insurers.Magma.logo
              : item?.insurerId === insurers.United.insurerId
              ? insurers.United.logo
              : item?.insurerId === insurers.shriram.insurerId
              ? insurers.shriram.logo
              : item?.insurerId === insurers.ICICI.insurerId
              ? insurers.ICICI.logo
              : item?.insurerId === insurers.TATA.insurerId
              ? insurers.TATA.logo
              : null,
            item?.insurer,
            "",
            "",
            motorRequest?.registrationNumber,
            item?.make,
            item?.model,
            item?.variant,
            item?.basicODPremium,
            item?.basicTPPremium,
            item?.netPremium,
            item?.gst,
            item?.finalPremium,
            item?.minIdv,
            item?.maxIdv,
            item?.idv,
            item?.policyStartDate,
            item?.policyEndDate,
            item?.discount,
            item?.insurer,
            item?.policyType,
            item?.roadSideAssistance?.isOpted,
            item?.roadSideAssistance?.isOpted
              ? item?.roadSideAssistance?.premium
              : item?.roadSideAssistance?.isOpted,
            item?.engineProtection?.isOpted,
            item?.engineProtection?.isOpted
              ? item?.engineProtection?.premium
              : item?.engineProtection?.isOpted,
            item?.tyreProtection?.isOpted,
            item?.tyreProtection?.isOpted
              ? item?.tyreProtection?.premium
              : item?.tyreProtection?.isOpted,
            item?.rimProtection?.isOpted,
            item?.rimProtection?.isOpted
              ? item?.rimProtection?.premium
              : item?.rimProtection?.isOpted,
            item?.consumables?.isOpted,
            item?.consumables?.isOpted
              ? item?.consumables?.premium
              : item?.consumables?.isOpted,
            item?.electricalAccessories?.isOpted,
            item?.electricalAccessories?.isOpted
              ? item?.electricalAccessories?.premium
              : item?.electricalAccessories?.isOpted,
            item?.nonElectricalAccessories?.isOpted,
            item?.nonElectricalAccessories?.isOpted
              ? item?.nonElectricalAccessories?.premium
              : item?.nonElectricalAccessories?.isOpted,
            item?.invoiceCover?.isOpted,
            item?.invoiceCover?.isOpted
              ? item?.invoiceCover?.premium
              : item?.invoiceCover?.isOpted,
            item?.ncbProtection?.isOpted,
            item?.ncbProtection?.isOpted
              ? item?.ncbProtection?.premium
              : item?.ncbProtection?.isOpted,
            item?.voluntaryDeductive?.isOpted,
            item?.voluntaryDeductive?.isOpted
              ? item?.voluntaryDeductive?.premium
              : item?.voluntaryDeductive?.isOpted,
            item?.passengerCover?.isOpted,
            item?.passengerCover?.isOpted
              ? item?.passengerCover?.premium
              : item?.passengerCover?.isOpted,
            item?.paCoverAmount?.isOpted,
            item?.paCoverAmount?.isOpted
              ? item?.paCoverAmount?.premium
              : item?.paCoverAmount?.isOpted,
            item?.lossOfPersonalBelongings?.isOpted,
            item?.lossOfPersonalBelongings?.isOpted
              ? item?.lossOfPersonalBelongings?.premium
              : item?.lossOfPersonalBelongings?.isOpted,
            item?.zeroDepriciationCover?.isOpted,
            item?.zeroDepriciationCover?.isOpted
              ? item?.zeroDepriciationCover?.premium
              : item?.zeroDepriciationCover?.isOpted,
            item?.keyReplacement?.isOpted,
            item?.keyReplacement?.isOpted
              ? item?.keyReplacement?.premium
              : item?.keyReplacement?.isOpted,

            item?.ncbDiscountAmount,
            item?.biFuelOd?.isOpted,
            item?.biFuelOd?.isOpted
              ? item?.biFuelOd?.premium
              : item?.biFuelOd?.isOpted,
            item?.biFuelTp?.isOpted,
            item?.biFuelTp?.isOpted
              ? item?.biFuelTp?.premium
              : item?.biFuelTp?.isOpted,
            item?.llToPaidDriver?.isOpted,
            item?.llToPaidDriver?.isOpted
              ? item?.llToPaidDriver?.premium
              : item?.llToPaidDriver?.isOpted,
            item?.caseId,
            item?.insurerId,
            item?.kycAdditionalData,
            item?.planId,
            item?.tppdDiscount ? item?.tppdDiscount : false,
            item?.loadingPremium ? item?.loadingPremium : false,
            item?.isAddonQuesAsk,
            item?.addonQuesArray,
            item?.isAdharRequiredInProposal,
            item?.isPanRequiredInProposal,
            item?.totalODAddons,
            item?.isGstNumberRequiredInProposal,
            item?.llToEmployeeCover?.isOpted,
            item?.llToEmployeeCover?.isOpted
              ? item?.llToEmployeeCover?.premium
              : item?.llToEmployeeCover?.isOpted
          )
        )
      )
    );
  };

  // useEffect(() => {
  //   if (initialCall) {
  //     motorQuotation(motorRequest);
  //     setInitialCall(false);
  //   }
  // }, [motorRequest]);

  const getMinMaxIdv = (arr) => {
    let minimumIdv = Math.pow(10, 1000);
    let maximumIdv = -1;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].MinIdv < minimumIdv) {
        minimumIdv = Math.ceil(arr[i].MinIdv);
      }
      if (arr[i].MaxIdv > maximumIdv) {
        maximumIdv = Math.floor(arr[i].MaxIdv);
      }
    }
    return { minimumIdv, maximumIdv };
  };

  useEffect(() => {
    const minMaxRange = getMinMaxIdv(QuickQouteResult);
    setMinMaxIdv(minMaxRange);
  }, [QuickQouteResult]);

  const handleInitialIdv = () => {
    let initialIdvRange = {
      Kotak: {
        min: "",
        max: "",
      },
      Digit: {
        min: "",
        max: "",
      },
    };
    QuickQouteResult.map((insurer) => {
      if (insurer?.Api_name === "digit") {
        initialIdvRange.Digit.min = insurer.MinIdv;
        initialIdvRange.Digit.max = insurer.MaxIdv;
      } else if (insurer?.Api_name === "Kotak") {
        initialIdvRange.Kotak.min = insurer.MinIdv;
        initialIdvRange.Kotak.max = insurer.MaxIdv;
      }
    });
    dispatchQuickQuote("initialIdvSet", true);
    dispatchQuickQuote("initialIdvRange", initialIdvRange);
    handleFilteredResult(apiRequestQQ);
  };

  const generatePdf = async () => {
    const input = document.getElementById("quotelistPdf");
    const inputWidth = input.scrollWidth;
    const inputHeight = input.scrollHeight;
    const scale = 2;
    const canvas = await html2canvas(input, { scale: scale, useCORS: true });
    const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("l", "pt", [
    //   (inputWidth * scale) / 3.78,
    //   (inputHeight * scale) / 3.78,
    // ]);
    const pdf = await PDFDocument.create();
    const page = pdf.addPage([
      (inputWidth * scale) / 3.78,
      (inputHeight * scale) / 3.78,
    ]);
    const pngImage = await pdf.embedPng(imgData);
    // pdf.addImage(
    //   imgData,
    //   "PNG",
    //   0,
    //   0,
    //   (inputWidth * scale) / 3.78,
    //   (inputHeight * scale) / 3.78
    // );
    // pdf.save("quotelistPdf.pdf");
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: (inputWidth * scale) / 3.78,
      height: (inputHeight * scale) / 3.78,
    });

    const pdfBytes = await pdf.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "quotelistPdf.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Layout>
        <div class="main-care-container" id="quotelistPdf">
          {/* <div class="container-div-1">
            <div className="d-flex align-items-center">
              <img
                src={
                  healthRequestQQ?.CustomerDetails?.customerMobileNumber &&
                  healthRequestQQ?.CustomerDetails?.customerRelation
                    ? "/assets/images/cardiogram.png"
                    : ""
                }
                alt=""
              />
              <h2>
                {healthRequestQQ?.CustomerDetails?.customerMobileNumber &&
                healthRequestQQ?.CustomerDetails?.customerRelation ? (
                  <>
                    <span>
                      {healthRequestQQ?.CustomerDetails?.customerFirstName}
                    </span>
                    <img
                      className="px-2"
                      src="/assets/images/Ellipse 232.png"
                    />
                  </>
                ) : (
                  ""
                )}
               
                {healthRequestQQ?.CustomerDetails?.customerMobileNumber &&
                healthRequestQQ?.CustomerDetails?.customerRelation
                  ? healthRequestQQ?.CustomerDetails?.customerRelation
                  : ""}
                {healthRequestQQ?.CustomerDetails?.customerMobileNumber &&
                healthRequestQQ?.CustomerDetails?.customerRelation ? (
                  <>
                    <img
                      className="px-2"
                      src="/assets/images/Ellipse 232.png"
                    />
                    <span>
                      {" "}
                      {healthRequestQQ?.CustomerDetails?.customerAge} Years
                    </span>
                  </>
                ) : (
                  ""
                )}
              </h2>
              {healthRequestQQ?.CustomerDetails?.customerMobileNumber &&
                healthRequestQQ?.CustomerDetails?.customerRelation && (
                  <h2
                    style={{
                      color: "green",
                      cursor: "pointer",
                      border: "0.5px solid green",
                      borderRadius: "5px",
                      padding: "1px 3px",
                      marginLeft: "10px",
                    }}
                  >
                    Edit
                  </h2>
                )}
            </div>
          </div> */}
          <div class="main-quote-list">
            <div class="row">
              {motorRequest?.vehicleType &&
              HealthQuickQuoteResult.length === 0 ? (
                <div className="col-md-12 col-lg-3">
                  <div className="invoice_sec">
                    <div className="row m-0 m-lg-0 p-0 align-items-lg-baseline">
                      <div class="col-1 d-block d-lg-none ps-0 pe-0">
                        <a href="">
                          <img
                            width="32"
                            height="32"
                            src="https://static.pbcdn.in/car-cdn/car2.0/back-btn.svg"
                            alt="car info"
                          />
                        </a>
                      </div>
                      <div className="col-10 ps-3 ps-md-0 ps-lg-0">
                        <h2 className="sec_heading">
                          {motorRequest?.makeName} {motorRequest?.modelName} (
                          {new Date(
                            motorRequest?.registrationDate
                          ).getFullYear()}
                          )
                        </h2>
                        {/* {motorRequest?.rtoCode ||
                          motorRequest?.isVehicleNew ||
                          (motorRequest?.registrationNumber && (
                            <span className="sec_text">
                              {motorRequest?.registrationNumber}
                            </span>
                          ))}{" "} */}
                        <span className="sec_text text-capitalize">
                          {motorRequest?.isVehicleNew
                            ? motorRequest?.rtoCode
                            : motorRequest?.registrationNumber}
                        </span>{" "}
                        |{" "}
                        <span className="sec_text text-capitalize">
                          {motorRequest?.variantName}
                        </span>{" "}
                        |{" "}
                        <span className="sec_text text-capitalize">
                          {motorRequest?.fuelType}
                        </span>
                      </div>
                      <div
                        className="col-1 col-lg-2 ps-0 pe-md-0 text-end"
                        onClick={() => {
                          setEditDetails(true);
                          setEditModal(true);
                          if (apiRequestQQ?.initialIdvSet === false) {
                            handleInitialIdv();
                          }
                        }}
                      >
                        <a className="edit">Edit</a>
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="invoice_list">
                      {motorRequest?.newPolicyType !==
                        POLICY_TYPE.THIRDPARTY && (
                        <>
                          <div className="row">
                            <div className="col-12 col-lg-7 pe-lg-0">
                              <p className="invoice_content">IDV Cover</p>
                            </div>
                            <div className="col-12 col-lg-5 ps-lg-0">
                              <span className="invoce_des">
                                <span
                                  className="clickable-text"
                                  onClick={() => setPlanDropdown(true)}
                                  // title="Edit IDV"
                                >
                                  {motorRequest?.idv !== 0
                                    ? motorRequest?.idv === Infinity
                                      ? 0
                                      : motorRequest?.idv
                                    : minIdv === Infinity
                                    ? 0
                                    : minIdv}
                                </span>
                                <HiMiniPencil
                                  onClick={() => setPlanDropdown(true)}
                                  style={{ marginLeft: "5px" }}
                                />
                                {/* <span className="arrow">
                                  <i className="fa-solid fa-chevron-right"></i>
                                </span> */}
                              </span>
                            </div>
                          </div>

                          {motorRequest?.isOwnerChanged === false &&
                            // motorRequest?.isPreviousInsuranceClaimed ===
                            //   false &&
                            motorRequest?.isVehicleNew === false &&
                            motorRequest?.businessType !==
                              POLICY_STATUS.EXPIREDBEFORE90 &&
                            !policyDateExceeded &&
                            motorRequest?.newPolicyType !== "noPlans" && (
                              <div className="row">
                                <div className="col-12 col-lg-7 pe-lg-0">
                                  <p className="invoice_content">Current NCB</p>
                                </div>
                                <div className="col-12 col-lg-5 ps-lg-0">
                                  <span className="invoce_des ">
                                    <span
                                      className="clickable-text-ncb"
                                      onClick={() => setNcbModel(true)}
                                    >
                                      {`${
                                        motorRequest?.isPreviousInsuranceClaimed
                                          ? "0%"
                                          : motorRequest?.prevNoClaimBonus === 0
                                          ? "20%"
                                          : motorRequest?.prevNoClaimBonus ===
                                            20
                                          ? "25%"
                                          : motorRequest?.prevNoClaimBonus ===
                                            25
                                          ? "35%"
                                          : motorRequest?.prevNoClaimBonus ===
                                            35
                                          ? "45%"
                                          : motorRequest?.prevNoClaimBonus ===
                                            45
                                          ? "50%"
                                          : motorRequest?.prevNoClaimBonus ===
                                            50
                                          ? "50%"
                                          : ""
                                      }`}
                                      <HiMiniPencil
                                        onClick={() => setNcbModel(true)}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    </span>
                                    {/* <span className="arrow">
                                  <i className="fa-solid fa-chevron-right"></i>
                                </span> */}
                                  </span>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                      {!motorRequest?.isVehicleNew &&
                        motorRequest.businessType !==
                          POLICY_STATUS.EXPIREDBEFORE90 && (
                          <div className="row">
                            <div className="col-12 col-lg-7 pe-lg-0">
                              <p className="invoice_content">
                                Policy Expiry Date
                              </p>
                            </div>
                            <div
                              className="col-12 col-lg-5 ps-lg-0"
                              onClick={() => {
                                setDateModal(true);
                                setDateModalOpenFromQuotelist(true);
                              }}
                            >
                              <span className="invoce_des ">
                                <span
                                  className="clickable-text-policyEndDate"
                                  onClick={() => {
                                    setDateModal(true);
                                    setDateModalOpenFromQuotelist(true);
                                  }}
                                >
                                  {motorRequest?.prevPolicyEndDate}
                                  <HiMiniPencil
                                    onClick={() => {
                                      setDateModal(true);
                                      setDateModalOpenFromQuotelist(true);
                                    }}
                                    style={{ marginLeft: "5px" }}
                                  />
                                </span>
                                {/* <span className="arrow">
                              <i className="fa-solid fa-chevron-right"></i>
                            </span> */}
                              </span>
                            </div>
                          </div>
                        )}
                      {/* <div className="row">
                        <div className="col-7 pe-0">
                          <p className="invoice_content">TP Expiry Date</p>
                        </div>
                        <div className="col-5 ps-0">
                          <span className="invoce_des">
                            21-Dec-2023{" "}
                            <span className="arrow">
                              <i className="fa-solid fa-chevron-right"></i>
                            </span>
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="invoice_sec">
                    <div className="plan_btn">
                      <div class="container-btn">
                        {/* {motorRequest?.newPolicyType !==
                          POLICY_TYPE.THIRDPARTY && (
                          <>
                            {QuickQouteResult.length !== 0 ? (
                              <button
                                onClick={() => {
                                  setPlanDropdown(true);
                                  if (apiRequestQQ?.initialIdvSet === false) {
                                    handleInitialIdv();
                                  }
                                }}
                              >
                                IDV :{" "}
                                {motorRequest?.idv !== 0
                                  ? motorRequest?.idv === Infinity
                                    ? 0
                                    : motorRequest?.idv
                                  : minIdv === Infinity
                                  ? 0
                                  : minIdv}
                                <IoMdArrowDropdown />
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  let newStructureData = { ...motorRequest };
                                  newStructureData["idv"] = 0;
                                  dispatchMotorQuote("idv", 0);
                                  dispatchMotorQuote("sessionId", "");
                                  motorQuotation(newStructureData);
                                }}
                              >
                                Reset IDV
                              </button>
                            )}

                            {motorRequest?.isVehicleNew === false && (
                              <button
                                onClick={() => {
                                  setNcbModel(true);
                                }}
                              >
                                Previous NCB : {motorRequest?.prevNoClaimBonus}%{" "}
                                <IoMdArrowDropdown />
                              </button>
                            )}
                          </>
                        )} */}
                        {!policyDateExceeded &&
                          motorRequest?.newPolicyType !== "noPlans" && (
                            <button
                              onClick={() => {
                                filterPlanTypeList();
                                setPlanModel(true);
                                if (apiRequestQQ?.initialIdvSet === false) {
                                  handleInitialIdv();
                                }
                              }}
                            >
                              Plan Type :{" "}
                              {motorRequest?.newPolicyType ===
                              POLICY_TYPE.ODONLY
                                ? "Own Damage"
                                : motorRequest?.newPolicyType ===
                                  POLICY_TYPE.COMPREHENSIVE
                                ? "Comprehensive"
                                : motorRequest?.newPolicyType ===
                                  POLICY_TYPE.THIRDPARTY
                                ? "Third Party"
                                : motorRequest?.newPolicyType ===
                                  POLICY_TYPE.BUNDLED
                                ? "Bundled"
                                : null}
                              <IoMdArrowDropdown />
                            </button>
                          )}
                        <button
                          onClick={() => {
                            setAddCover(true);
                            if (apiRequestQQ?.initialIdvSet === false) {
                              handleInitialIdv();
                            }
                          }}
                        >
                          Add-ons <IoMdArrowDropdown />
                        </button>
                        {motorRequest?.newPolicyType !== POLICY_TYPE.ODONLY && (
                          <button
                            onClick={() => {
                              setAccidentCoverModal(true);
                              if (apiRequestQQ?.initialIdvSet === false) {
                                handleInitialIdv();
                              }
                            }}
                          >
                            Accident Covers <IoMdArrowDropdown />
                          </button>
                        )}
                        {motorRequest?.vehicleType !==
                          VEHICLE_TYPE.TWO_WHEELER && (
                          <button
                            onClick={() => {
                              setAccessoriesCoverModal(true);
                              if (apiRequestQQ?.initialIdvSet === false) {
                                handleInitialIdv();
                              }
                            }}
                          >
                            Accessories Covers <IoMdArrowDropdown />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {healthRequestQQ.enquiryId != "" ? (
                // HealthQuickQuoteResult.length > 0 &&
                // QuickQouteResult.length === 0
                <div className="col-md-12 col-lg-3">
                  <div className="invoice_sec">
                    <div className="plan_btn">
                      <div class="container-btn">
                        <p style={{ minWidth: "100px" }}>Fast Filters</p>
                        <button onClick={() => setCoverModel(true)}>
                          Cover <IoMdArrowDropdown />
                        </button>
                        <button onClick={() => setSortModel(true)}>
                          Tenure <IoMdArrowDropdown />
                        </button>
                        <button
                          className={
                            healthFilter.coverageNonMedical === true
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            handelHealthFilterSelect("coverageNonMedical")
                          }
                        >
                          {healthFilter.coverageNonMedical === true && (
                            <FaCheck style={{ marginRight: "10px" }} />
                          )}{" "}
                          Coverage For Non-Medical Items
                        </button>
                        <button
                          className={
                            healthFilter.preExistingDiseaseWaitingPeriod ===
                            true
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            handelHealthFilterSelect(
                              "preExistingDiseaseWaitingPeriod"
                            )
                          }
                        >
                          {healthFilter.preExistingDiseaseWaitingPeriod ===
                            true && (
                            <FaCheck style={{ marginRight: "10px" }} />
                          )}{" "}
                          Pre-Existing Disease Waiting Period
                        </button>
                        {healthRequestQQ?.CustomerDetails?.customerGender ===
                          "Female" &&
                          healthRequestQQ?.CustomerDetails?.customerAge !==
                            "18" && (
                            <button
                              className={
                                healthFilter.maternityChildcareBenefit === true
                                  ? "active"
                                  : "text-truncate"
                              }
                              onClick={() =>
                                handelHealthFilterSelect(
                                  "maternityChildcareBenefit"
                                )
                              }
                            >
                              {healthFilter.maternityChildcareBenefit ===
                                true && (
                                <FaCheck style={{ marginRight: "10px" }} />
                              )}{" "}
                              Maternity Cover
                            </button>
                          )}
                        {/* <button
                        className={
                          healthFilterSelect?.includes("Discount") ? "active" : ""
                        }
                        style={{ minWidth: "120px" }}
                        onClick={() => handelHealthFilterSelect("Discount")}
                      >
                        {" "}
                        {healthFilterSelect?.includes("Discount") && (
                          <FaCheck style={{ marginRight: "10px" }} />
                        )}{" "}
                        Discount
                      </button>
                      <button
                        style={{ minWidth: "90px" }}
                        onClick={() => setInsurerModel(true)}
                      >
                        Insurer <IoMdArrowDropdown />
                      </button>
                      <button
                        style={{ minWidth: "120px" }}
                        onClick={() => setPlanTypeModel(true)}
                      >
                        Plan Type <IoMdArrowDropdown />
                      </button>
                      <button
                        style={{ minWidth: "100px" }}
                        onClick={() => setFilterModel(true)}
                      >
                        <FaFilter /> All Filters
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-12 col-lg-6">
                <div class="list-quotes">
                  {motorRequest?.vehicleType &&
                  HealthQuickQuoteResult.length === 0 ? (
                    // apiRequestQQ?.RegistrationNumber != ""
                    <>
                      {/* <div class="container-btn container-btn-second">
                        <div className="add-ons-inner">
                          <label>
                            <input
                              type="checkbox"
                              value="true"
                              // checked={zeroDepreciationCover}
                              // onChange={handleCheckboxChange}
                              onChange={() =>
                                setZeroDepreciationCover(!zeroDepreciationCover)
                              }
                              onClick={() => checkFilters()}
                            />
                            Zero Depreciation Cover
                          </label>
                        </div>
                        <div className="add-ons-inner">
                          <label>
                            <input type="checkbox" value="true" />
                            Pay As you Drive
                          </label>
                        </div>
                      </div> */}
                    </>
                  ) : (
                    ""
                  )}

                  {!policyDateExceeded &&
                    motorRequest?.newPolicyType !== "noPlans" && (
                      <div class="quote-text">
                        <span>
                          {HealthQuickQuoteResult.length > 0
                            ? HealthQuickQuoteResult.length + " Comprehensive"
                            : QuickQouteResult.length}{" "}
                          {motorRequest?.newPolicyType === POLICY_TYPE.ODONLY
                            ? "Own Damage"
                            : motorRequest?.newPolicyType ===
                              POLICY_TYPE.COMPREHENSIVE
                            ? "Comprehensive"
                            : motorRequest?.newPolicyType ===
                              POLICY_TYPE.THIRDPARTY
                            ? "Third Party"
                            : motorRequest?.newPolicyType ===
                              POLICY_TYPE.BUNDLED
                            ? "Bundled"
                            : null}{" "}
                          {QuickQouteResult.length > 1 ? "Plans" : "Plan"}{" "}
                          offered
                        </span>
                        {/* {processFlowObjMotor?.quotationNumber && ( */}
                        <h5
                          className="toggleShareQuotePaymentModal mx-1"
                          onClick={toggleShareQuotePaymentModal}
                        >
                          <IoMdShare />
                          {processFlowObjMotor?.quotationNumber}
                        </h5>

                        {processFlowObjHealth?.quotationNumber && (
                          <span>
                            Quotation Number :{" "}
                            {processFlowObjHealth?.quotationNumber}
                          </span>
                        )}
                      </div>
                    )}

                  {HealthQuickQuoteResult &&
                  HealthQuickQuoteResult.length > 0 ? (
                    HealthQuickQuoteResult.map((item, index) => {
                      return (
                        <div key={index} class="quote-card  health_quote">
                          <div class="quote-card-1">
                            <div className="quote-card-1">
                              <div className="quote-right">
                                <div class="quote-img d-flex flex-column">
                                  <img src={item?.logo} alt="" />
                                  {/* <p
                                  className="mt-2"
                                  style={{ fontSize: "1rem" }}
                                >
                                  {item?.planType}
                                </p> */}
                                </div>
                                <div class="care-hospital">
                                  <h3>{item?.Company}</h3>
                                  <p
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#58b96c",
                                      fontWeight: "600",
                                      textAlign: "start",
                                    }}
                                  >
                                    {item?.planType}{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="quote-left">
                                <div class="care-hospital">
                                  <h6>Cashless hospitals</h6>
                                  <p
                                    style={{
                                      color: "#56B96C",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setCashlessHospitals(
                                        item?.cashlessHospital?.totalHospital
                                      );
                                      setCashlessHospitalList(true);
                                    }}
                                  >
                                    {item?.cashlessHospital?.hospitalList}
                                    <IoMdArrowDropdown />
                                  </p>
                                </div>
                                <div class="care-hospital">
                                  <h6>Cover</h6>
                                  <p>₹{item?.sumInsuredValue}</p>
                                </div>
                                {/* <div class="care-amount">
                                <h6>Plan Tenure</h6>
                                <p>{item?.planTenure}</p>
                              </div> */}
                                <div class="quote-btn">
                                  {/* <p>
                                {" "}
                                <input type="checkbox" />
                                <span>Add to Compare</span>
                                </p> */}

                                  <button
                                    className=""
                                    onClick={() => submitUserPlan(item)}
                                  >
                                    {/* ₹{item?.PremiumAmount?.premiumPerMonth}
                                  /month */}
                                    ₹{item?.PremiumAmount?.premiumPerMonth} /
                                    month
                                    <span className="ms-2">
                                      <FaArrowRightLong />
                                    </span>
                                  </button>
                                  <p style={{ fontSize: "0.8rem" }}>
                                    {" "}
                                    ₹{item?.PremiumAmount?.premiumPerYear} /
                                    year
                                  </p>
                                  {/* 
                                <p
                                  className="mt-1"
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "#58b96c",
                                  }}
                                >
                                  {item?.planType}
                                </p> */}

                                  {/* <p class="care-text text-center">
                                  ₹{item?.PremiumAmount?.premiumPerYear}/year
                                </p> */}
                                </div>
                              </div>

                              {/* <div className="vr-line"></div> */}
                            </div>
                            <div className="addon">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Applied Addons
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <div className="product-select">
                                        <ul className="product_box">
                                          {healthFilter.coverageNonMedical ===
                                            true && (
                                            <li className="product_list">
                                              <>
                                                <span className="list_img">
                                                  <img
                                                    src="/assets/images/check.png"
                                                    style={{ width: "12px" }}
                                                  ></img>
                                                </span>
                                                <span className="text-start">
                                                  Coverage For Non-Medical Items
                                                </span>
                                              </>
                                            </li>
                                          )}

                                          {healthFilter.preExistingDiseaseWaitingPeriod ===
                                            true && (
                                            <li className="product_list">
                                              <>
                                                <span className="list_img">
                                                  <img
                                                    src="/assets/images/check.png"
                                                    style={{ width: "12px" }}
                                                  ></img>
                                                </span>
                                                <span className="text-start">
                                                  Pre-Existing Disease Waiting
                                                  Period
                                                </span>
                                              </>
                                            </li>
                                          )}

                                          {healthFilter.maternityChildcareBenefit ===
                                            true && (
                                            <li className="product_list">
                                              <>
                                                <span className="list_img">
                                                  <img
                                                    src="/assets/images/check.png"
                                                    style={{ width: "12px" }}
                                                  ></img>
                                                </span>
                                                <span className="text-start">
                                                  Maternity Cover
                                                </span>
                                              </>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex w-100">
                              {healthFilter.coverageNonMedical === true && (
                                <span
                                  className="text-start mx-5"
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "#56B96C",
                                  }}
                                >
                                  Coverage For Non-Medical Items
                                </span>
                              )}
                              {healthFilter.preExistingDiseaseWaitingPeriod ===
                                true && (
                                <span
                                  className="text-start mx-5"
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "#56B96C",
                                  }}
                                >
                                  Pre-Existing Disease Waiting Period
                                </span>
                              )}
                              {healthFilter.maternityChildcareBenefit ===
                                true && (
                                <span
                                  className="text-start mx-5"
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "#56B96C",
                                  }}
                                >
                                  Maternity and Childcare Benefit Waiting Period
                                  Modification
                                </span>
                              )}
                            </div> */}
                          </div>
                        </div>
                      );
                    })
                  ) : QuickQouteResult && QuickQouteResult.length > 0 ? (
                    QuickQouteResult.map((item, index) => {
                      if (typeof item?.NetPremium === "number") {
                        var priceWithoutINR =
                          item?.NetPremium.toString().replace("INR", "");
                      } else if (typeof item?.NetPremium === "string") {
                        var priceWithoutINR = item?.NetPremium.replace(
                          "INR",
                          ""
                        );
                      } else {
                        var priceWithoutINR = item?.NetPremium;
                      }
                      return (
                        <>
                          <div key={index} class="quote-card">
                            <div class="quote-card-1">
                              <div class="quote-card-1">
                                <div
                                  class={`quote-img ${
                                    item?.Api_name === "digit"
                                      ? "digit_img"
                                      : ""
                                  }`}
                                >
                                  <img src={item?.Logo} alt="" />
                                </div>
                                {/* <div class="quote-heading">
                                <h3>{item?.insurer}</h3>
                                <p>{item?.insurer}</p>
                              </div> */}
                                {/* <div className="vr-line"></div> */}

                                <div class="care-amount">
                                  {motorRequest?.newPolicyType !==
                                  POLICY_TYPE.THIRDPARTY ? (
                                    <>
                                      {/* IDV MID */}
                                      <h6
                                        className=""
                                        onClick={togglePACoverModal}
                                      >
                                        IDV
                                      </h6>
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{
                                          color: "#6D6D6D",
                                        }}
                                      >
                                        <p className="idv_min">
                                          {" "}
                                          Min ₹{parseInt(item?.MinIdv)}
                                        </p>
                                        <span
                                          className="mx-2"
                                          style={{ fontSize: "0.7rem" }}
                                        >
                                          -
                                        </span>
                                        <p className="idv_min">
                                          {" "}
                                          Max ₹{parseInt(item?.MaxIdv)}
                                        </p>
                                      </div>
                                      <p className="">₹{item?.idv}</p>

                                      {/* Second IDV */}

                                      {/* <div className="d-flex justify-content-center">
                                        <h6 className="mx-5">Min</h6>
                                        <h6 className="mx-5">IDV</h6>
                                        <h6 className="mx-5">Max</h6>
                                      </div>

                                      <div className="d-flex justify-content-center">
                                        <p className="mx-3">₹{item?.MinIdv}</p>
                                        <p className="mx-5">₹{item?.idv}</p>
                                        <p className="mx-3">₹{item?.MaxIdv}</p>
                                      </div> */}
                                      {/* <div className="d-flex">
                                        <div>
                                          {" "}
                                          <h6 className="mx-5">IDV</h6>
                                          <p className="mx-5">₹{item?.idv}</p>
                                        </div>
                                        <div>
                                          {" "}
                                          <h6 className="mx-5">Min - Max</h6>
                                          <p className="mx-5">₹{item?.idv}</p>
                                        </div>
                                      </div> */}
                                      {/* <h6 className="mx-5">IDV</h6>
                                      <p className="mx-5">₹{item?.idv}</p> */}
                                    </>
                                  ) : (
                                    <h6>Third Party</h6>
                                  )}
                                </div>

                                <div class="quote-btn">
                                  <button onClick={() => submitUserPlan(item)}>
                                    ₹{Math.round(priceWithoutINR)}
                                    <span className="ms-2">
                                      <FaArrowRightLong />
                                    </span>
                                  </button>
                                </div>
                                <div className="addon">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          className="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          Applied Addons
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                        style={{}}
                                      >
                                        <div className="accordion-body">
                                          <div className="product-select">
                                            <ul className="product_box">
                                              {/* {(apiRequestQQ?.AddOns
                                                ?.ZeroDepriciationCover ||
                                                item?.ZeroDepriciationCover !==
                                                  false) &&
                                              apiRequestQQ?.NewPolicyType !==
                                                "ThirdParty" ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.ZeroDepriciationCover ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Zero Depriciation Cover
                                                    </span>
                                                  </>
                                                </li>
                                              ) : item?.Api_name === "Kotak" &&
                                                item?.ZeroDepriciationCover !==
                                                  false ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.ZeroDepriciationCover ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Zero Depriciation Cover
                                                    </span>
                                                  </>
                                                </li>
                                              ) : (
                                                ""
                                              )} */}

                                              {item?.insurerId == 28 &&
                                                motorRequest?.customerType ===
                                                  "organisation" &&
                                                item?.LlToEmployeeCover && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.LlToEmployeeCover ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Legal Liabilities To
                                                        Employee Cover
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.ZeroDepriciationCover
                                                : (motorRequest?.zeroDepriciationCover ||
                                                    item?.ZeroDepriciationCover) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.ZeroDepriciationCover ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Zero Depreciation
                                                          Cover
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.RoadSideAssistance
                                                : (motorRequest?.roadSideAssistance ||
                                                    item?.RoadSideAssistance) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.RoadSideAssistance ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Road Side Assistance
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.Consumables
                                                : (motorRequest?.consumables ||
                                                    item?.Consumables) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.Consumables ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Consumables
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.KeyReplacement
                                                : (motorRequest?.keyReplacement ||
                                                    item?.KeyReplacement) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.KeyReplacement ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Key Replacement
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.InvoiceCover
                                                : (motorRequest?.invoiceCover ||
                                                    item?.InvoiceCover) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.InvoiceCover ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Invoice Cover
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.NCBProtection
                                                : (motorRequest?.ncbProtection ||
                                                    item?.NCBProtection) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.NCBProtection ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          NCB Protection
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.LossOfPersonalBelongings
                                                : (motorRequest?.lossOfPersonalBelongings ||
                                                    item?.LossOfPersonalBelongings) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.LossOfPersonalBelongings ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Loss Of Personal
                                                          Belongings
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.EngineProtection
                                                : (motorRequest?.engineProtection ||
                                                    item?.EngineProtection) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.EngineProtection ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Engine Protection
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.TyreProtection
                                                : (motorRequest?.tyreProtection ||
                                                    item?.TyreProtection) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.TyreProtection ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Tyre Protection
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.RimProtection
                                                : (motorRequest?.rimProtection ||
                                                    item?.RimProtection) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.RimProtection ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Rim Protection
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.PersonalAccident
                                                : (motorRequest?.personalAccident ||
                                                    item?.PersonalAccident) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.PersonalAccident ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Personal Accident
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {(motorRequest?.isTppdDiscount ||
                                                item?.tppdDiscount) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.tppdDiscount ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Tppd Discount
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {(motorRequest?.isVoluntaryDeductable ||
                                                item?.VoluntaryDeductive) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.VoluntaryDeductive ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Voluntary Deductive
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.IsElectricalAccessories
                                                : (motorRequest?.isElectricalAccessories ||
                                                    item?.IsElectricalAccessories) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.IsElectricalAccessories ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Electrical Accessories
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {motorRequest?.newPolicyType ===
                                              POLICY_TYPE.THIRDPARTY
                                                ? item?.IsNonElectricalAccessories
                                                : (motorRequest?.isNonElectricalAccessories ||
                                                    item?.IsNonElectricalAccessories) && (
                                                    <li className="product_list">
                                                      <>
                                                        <span className="list_img">
                                                          <img
                                                            src={
                                                              item?.IsNonElectricalAccessories ===
                                                              false
                                                                ? "/assets/images/cancel (1).png"
                                                                : "/assets/images/check.png"
                                                            }
                                                            style={{
                                                              width: "12px",
                                                            }}
                                                          ></img>
                                                        </span>
                                                        <span className="product_text">
                                                          Non Electrical
                                                          Accessories
                                                        </span>
                                                      </>
                                                    </li>
                                                  )}

                                              {(motorRequest?.isCngAccessories ||
                                                item?.BiFuelOd ||
                                                item?.BiFuelTp) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          (item?.BiFuelOd ||
                                                            item?.BiFuelTp) ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Cng Accessories
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {(motorRequest?.isPassengerCover ||
                                                item?.PassengerCover) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.PassengerCover ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Passenger Cover
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {(motorRequest?.liabilitiesToPaidDriver ||
                                                item?.liabilitiesToPaidDriver) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.liabilitiesToPaidDriver ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Liabilities To Paid Driver
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {/* {(apiRequestQQ?.AddOns
                                                ?.RoadSideAssistance ||
                                                item?.RoadSideAssistance !==
                                                  false) &&
                                              apiRequestQQ?.NewPolicyType !==
                                                "ThirdParty" ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.RoadSideAssistance ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Road Side Assistance
                                                    </span>
                                                  </>
                                                </li>
                                              ) : item?.Api_name === "Kotak" &&
                                                item?.RoadSideAssistance !==
                                                  false ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.RoadSideAssistance ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Road Side Assistance
                                                    </span>
                                                  </>
                                                </li>
                                              ) : (
                                                ""
                                              )}

                                              {(apiRequestQQ?.AddOns
                                                ?.EngineProtection === true ||
                                                item?.EngineProtection !==
                                                  false) &&
                                              apiRequestQQ?.NewPolicyType !==
                                                "ThirdParty" ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.EngineProtection ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Engine Protection
                                                    </span>
                                                  </>
                                                </li>
                                              ) : (
                                                ""
                                              )}

                                              {(apiRequestQQ?.AddOns
                                                ?.TyreProtection === true ||
                                                item?.TyreProtection !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.TyreProtection ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Tyre Protection
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.RimProtection === true ||
                                                item?.RimProtection !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.RimProtection ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Rim Protection
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.Consumables === true ||
                                                item?.Consumables !== false) &&
                                              apiRequestQQ?.NewPolicyType !==
                                                "ThirdParty" ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.Consumables ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Consumables
                                                    </span>
                                                  </>
                                                </li>
                                              ) : item?.Api_name === "Kotak" &&
                                                item?.Consumables !== false ? (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.Consumables ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Consumables
                                                    </span>
                                                  </>
                                                </li>
                                              ) : (
                                                ""
                                              )}

                                              {(apiRequestQQ?.AddOns
                                                ?.IsElectricalAccessories ===
                                                true ||
                                                item?.IsElectricalAccessories !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.IsElectricalAccessories ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Is Electrical
                                                        Accessories
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.IsNonElectricalAccessories ===
                                                true ||
                                                item?.IsNonElectricalAccessories !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.IsNonElectricalAccessories ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Is Non Electrical
                                                        Accessories
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.IsCngAccessories === true ||
                                                item?.CNGValue !== false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.CNGValue ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Is Cng Accessories
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.PersonalAccident ||
                                                item?.PersonalAccident !==
                                                  false) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.PersonalAccident ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Personal Accident
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {(apiRequestQQ?.AddOns
                                                ?.InvoiceCover === true ||
                                                item?.InvoiceCover !== false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.InvoiceCover ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Invoice Cover
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {motorRequest?.isTppdiscount ||
                                                (item?.tppdDiscount && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.tppdDiscount ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Tppd Discount
                                                      </span>
                                                    </>
                                                  </li>
                                                ))}

                                              {(apiRequestQQ?.AddOns
                                                ?.NCBProtection === true ||
                                                item?.NCBProtection !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.NCBProtection ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        NCB Protection
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.VoluntaryDeductive === true ||
                                                item?.VoluntaryDeductive !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.VoluntaryDeductive ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Voluntary Deductive
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.PassengerCover === true ||
                                                item?.PassengerCover !==
                                                  false) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.PassengerCover ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Passenger Cover
                                                    </span>
                                                  </>
                                                </li>
                                              )}

                                              {(apiRequestQQ?.AddOns
                                                ?.LossOfPersonalBelongings ===
                                                true ||
                                                item?.LossOfPersonalBelongings !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.LossOfPersonalBelongings ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Loss Of Personal
                                                        Belongings
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.KeyReplacement === true ||
                                                item?.KeyReplacement !==
                                                  false) &&
                                                apiRequestQQ?.NewPolicyType !==
                                                  "ThirdParty" && (
                                                  <li className="product_list">
                                                    <>
                                                      <span className="list_img">
                                                        <img
                                                          src={
                                                            item?.KeyReplacement ===
                                                            false
                                                              ? "/assets/images/cancel (1).png"
                                                              : "/assets/images/check.png"
                                                          }
                                                          style={{
                                                            width: "12px",
                                                          }}
                                                        ></img>
                                                      </span>
                                                      <span className="product_text">
                                                        Key Replacement
                                                      </span>
                                                    </>
                                                  </li>
                                                )}

                                              {(apiRequestQQ?.AddOns
                                                ?.LiabilitiesToPaidDriver ||
                                                item?.DriverLiability !==
                                                  false) && (
                                                <li className="product_list">
                                                  <>
                                                    <span className="list_img">
                                                      <img
                                                        src={
                                                          item?.DriverLiability ===
                                                          false
                                                            ? "/assets/images/cancel (1).png"
                                                            : "/assets/images/check.png"
                                                        }
                                                        style={{
                                                          width: "12px",
                                                        }}
                                                      ></img>
                                                    </span>
                                                    <span className="product_text">
                                                      Liabilities To Paid Driver
                                                    </span>
                                                  </>
                                                </li>
                                              )} */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : policyDateExceeded ? (
                    <PolicyDateError />
                  ) : motorRequest?.newPolicyType === "noPlans" ||
                    noPlanToShow ||
                    noPlans ? (
                    <PolicyDateError />
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div class="customer-review">
                  <div class="customer-deatils">
                    <div class="my-deatils">
                      <div class="my-img">
                        <img src="/assets/images/profile.png" alt="" />
                      </div>
                      <div class="my-name">
                        <h5>
                          <b>Ajay </b>(27 Yrs)
                        </h5>
                        <p>Customer since 2023</p>
                      </div>
                    </div>
                    <p>
                      Once I contacted Insurefast claims team, all the
                      follow-ups were done by them and I got my claim in less
                      than a month.
                    </p>
                    {/* <button>
                    <a href="#">see story</a>
                  </button> */}
                    <p className="customer-deatils-see-story">see story</p>
                    <div class="author-customer">
                      <img src="/assets/images/next-img.png" alt="" />
                    </div>
                  </div>

                  {/* <div class="adds-card">
                  <img src="/assets/images/healthInsuranePlan.jpg" alt="" />
                  <img src="/assets/images/motor insurance copy.jpg" alt="" />
                </div> */}
                </div>
              </div>
            </div>

            {ApiErrorArr &&
              ApiErrorArr.length > 0 &&
              (motorRequest?.source === source.employee ? (
                <div className="quote_follower">
                  <div className="row">
                    {ApiErrorArr ? (
                      ApiErrorArr.map((item, key) => (
                        <div className="col-12 col-lg-4 col-md-6">
                          <div className="quote_follower">
                            <ul className="insurer_list">
                              <li className="insurer_img">
                                <a>
                                  <img
                                    key={key}
                                    src={
                                      (item.insurerId ===
                                        insurers.Bajaj.insurerId &&
                                        insurers.Bajaj.logo) ||
                                      (item.insurerId ===
                                        insurers.Future.insurerId &&
                                        insurers.Future.logo) ||
                                      (item.insurerId ===
                                        insurers.Digit.insurerId &&
                                        insurers.Digit.logo) ||
                                      (item.insurerId ===
                                        insurers.Kotak.insurerId &&
                                        insurers.Kotak.logo) ||
                                      (item.insurerId ===
                                        insurers.Sompo.insurerId &&
                                        insurers.Sompo.logo) ||
                                      (item.insurerId ===
                                        insurers.Magma.insurerId &&
                                        insurers.Magma.logo) ||
                                      (item.insurerId ===
                                        insurers.United.insurerId &&
                                        insurers.United.logo) ||
                                      (item.insurerId ===
                                        insurers.shriram.insurerId &&
                                        insurers.shriram.logo) ||
                                      (item.insurerId ===
                                        insurers.ICICI.insurerId &&
                                        insurers.ICICI.logo) ||
                                      (item.insurerId ===
                                        insurers.TATA.insurerId &&
                                        insurers.TATA.logo)
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <p>
                                  {item.displayMessage}
                                  <br />
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>
                        Quotes Not Generted
                        <br />
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="quote_follower">
                      <p className="text-center mb-4">
                        We did not get a quote from the following insurers
                        <br />
                        Might be possible reason at insurance company's end like
                        MMV decline or RTO restricted or some technical issue.
                        <br />
                      </p>
                      <ul className="insurer_list">
                        {ApiErrorArr.map((item, key) => (
                          <li className="insurer_img">
                            <a>
                              <img
                                key={key}
                                src={
                                  (item.insurerId ===
                                    insurers.Bajaj.insurerId &&
                                    insurers.Bajaj.logo) ||
                                  (item.insurerId ===
                                    insurers.Future.insurerId &&
                                    insurers.Future.logo) ||
                                  (item.insurerId ===
                                    insurers.Digit.insurerId &&
                                    insurers.Digit.logo) ||
                                  (item.insurerId ===
                                    insurers.Kotak.insurerId &&
                                    insurers.Kotak.logo) ||
                                  (item.insurerId ===
                                    insurers.Sompo.insurerId &&
                                    insurers.Sompo.logo) ||
                                  (item.insurerId ===
                                    insurers.Magma.insurerId &&
                                    insurers.Magma.logo) ||
                                  (item.insurerId ===
                                    insurers.United.insurerId &&
                                    insurers.United.logo) ||
                                  (item.insurerId ===
                                    insurers.shriram.insurerId &&
                                    insurers.shriram.logo) ||
                                  (item.insurerId ===
                                    insurers.ICICI.insurerId &&
                                    insurers.ICICI.logo) ||
                                  (item.insurerId === insurers.TATA.insurerId &&
                                    insurers.TATA.logo)
                                }
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Layout>
      {ncbModel && (
        <QuotelistModel
          show={ncbModel}
          setModel={setNcbModel}
          handleFilteredResult={handleFilteredResult}
        />
      )}
      {planModel && (
        <QuotelistModelPlan
          show={planModel}
          setModel={setPlanModel}
          filter={"Plan Model"}
          filteredList={filterLIST}
          handleFilteredResult={handleFilteredResult}
        />
      )}
      {planDropdown && (
        <QuotelistDropdown
          show={planDropdown}
          setModel={setPlanDropdown}
          minMaxIdv={minMaxIdv}
          handleFilteredResult={handleFilteredResult}
          sessionId={
            getMotorQuoteResponse?.sessionId || motorRequest?.sessionId
          }
        />
      )}
      {addCover && (
        <QuotelistAddOns
          show={addCover}
          setModel={setAddCover}
          handleFilteredResult={handleFilteredResult}
          addOnfilter={addOnfilter}
          setAddOnfilter={setAddOnfilter}
        />
      )}
      {accidentCoverModal && (
        <QuotelistAccidentCovers
          show={accidentCoverModal}
          setModel={setAccidentCoverModal}
          handleFilteredResult={handleFilteredResult}
          accidentFilter={accidentFilter}
          setAccidentFilter={setAccidentFilter}
        />
      )}
      {accessoriesCoverModal && (
        <QuotelistAccessoriesCovers
          show={accessoriesCoverModal}
          setModel={setAccessoriesCoverModal}
          handleFilteredResult={handleFilteredResult}
          accessoriesFilter={accessoriesFilter}
          setAccessoriesFilter={setAccessoriesFilter}
        />
      )}

      {coverModel && (
        <QuotelistModelPlanHealth
          list={coverModelList}
          heading={"Cover"}
          show={coverModel}
          setModel={setCoverModel}
        />
      )}
      {sortModel && (
        <QuotelistModelPlanHealth
          list={sortByList}
          heading={"Sort By"}
          show={sortModel}
          setModel={setSortModel}
        />
      )}
      {insurer && (
        <QuotelistModelPlanHealth
          list={insurerList}
          heading={"Insurer"}
          show={insurer}
          setModel={setInsurerModel}
        />
      )}
      {planTypeModel && (
        <QuotelistModelPlanHealth
          show={planTypeModel}
          list={planTypeList}
          heading={"Plan Type"}
          setModel={setPlanTypeModel}
        />
      )}
      {filterModel && (
        <QuotelistAllFilter show={filterModel} setModel={setFilterModel} />
      )}
      {cashlessHospitalList && (
        <QuotelistCashlessHospitalList
          show={cashlessHospitalList}
          list={cashlessHospitals}
          heading={"Cashless Hospitals"}
          setModel={setCashlessHospitalList}
        />
      )}
      {editDetails && (
        <EditDetailsModal
          show={setEditModal}
          setModal={setEditDetails}
          filterPlanTypeList={filterPlanTypeList}
          handleFilteredResult={handleFilteredResult}
          motorQuotation={motorQuotation}
          noPlans={noPlans}
          setNoPlans={setNoPlans}
          setPolicyDateExceeded={setPolicyDateExceeded}
          policyDateExceeded={policyDateExceeded}
        />
      )}

      {premiumBreakup && (
        <PremiumBreakup
          show={setPremiumBreakupModal}
          setModal={setPremiumBreakup}
          planDetails={planDetail}
        />
      )}

      {sharePaymentModal && (
        <SharePaymentQuotationModal
          sharePaymentModal={sharePaymentModal}
          toggleSharePaymentModal={toggleShareQuotePaymentModal}
          generatePdf={generatePdf}
          quotationPage={"quotationPage"}
        />
      )}

      {prevAddonsModal && (
        <PrevAddonsModal
          prevAddonsModal={prevAddonsModal}
          togglePrevAddonsModal={togglePrevAddonsModal}
          motorQuotation={motorQuotation}
          addonsQuesAsk={addonsQuesAsk}
          setPremiumBreakupModal={setPremiumBreakup}
          planDetail={planDetail}
        />
      )}

      {dateModal && (
        <PrevDateModal
          dateModal={dateModal}
          setDateModal={setDateModal}
          toggle={toggleDateModal}
          setNcbModel={setNcbModel}
          dateModalOpenFromQuotelist={dateModalOpenFromQuotelist}
          handleFilteredResult={handleFilteredResult}
          policyDateExceeded={policyDateExceeded}
          setPolicyDateExceeded={setPolicyDateExceeded}
        />
      )}
    </>
  );
};

export default Quotelist;

const coverModelList = [
  // {
  //   label: "Recommended",
  //   key: "Recommended",
  // },
  {
    label: "5 Lakhs",
    key: "5LAC",
  },
  {
    label: "7.5 Lakhs",
    key: "7.5LAC",
  },
  {
    label: "10 Lakhs",
    key: "10LAC",
  },
  {
    label: "12.5 Lakhs",
    key: "12.5LAC",
  },
  {
    label: "15 Lakhs",
    key: "15LAC",
  },
  {
    label: "20 Lakhs",
    key: "20LAC",
  },
  {
    label: "25 Lakhs",
    key: "25LAC",
  },
  {
    label: "50 Lakhs",
    key: "50LAC",
  },
  {
    label: "75 Lakhs",
    key: "75LAC",
  },
  {
    label: "100 Lakhs",
    key: "100LAC",
  },
];

const sortByList = [
  {
    label: "1 Year",
    key: "1year",
  },
  {
    label: "2 Years",
    key: "2year",
  },
  {
    label: "3 Years",
    key: "3year",
  },
];

const insurerList = [
  {
    label: "Universal Sompo General Insurance",
    key: "USGI",
  },
  // {
  //   label: "Adity Birla",
  //   key: "Birla",
  // },
  // {
  //   label: "Care Health",
  //   key: "Health",
  // },
];

const planTypeList = [
  {
    label: "Base Plan",
    key: "Plan",
  },
  {
    label: "1Cr Cover",
    key: "1",
  },
  {
    label: "Multi-Individual Plans",
    key: "Multi",
  },
  {
    label: "Arogya Sanjeevani",
    key: "Sanjeevani",
  },
  {
    label: "Top-up Plan",
    key: "Top",
  },
];
