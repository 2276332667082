import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "../QuotelistComponents/Quotelist.css";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createCustomerHealth,
  getKyc,
  processFlow,
  sendOtp,
  verifyKycOtp,
  verifyOtp,
} from "../services/TPApiCall";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  healthQuickQuoteResult,
} from "../../store/action/userActions";
import store from "../../store";
import { useNavigate } from "react-router-dom";
import { apiKycStatus, toastFailure } from "../utility/constants";
import { toast } from "react-toastify";
function OtpModel({
  show,
  setModel,
  otpLength,
  resendObj,
  editMobileNumber,
  mobileNumber,
  setRedirectionUrl,
  setKycStatus,
  setProceedSpinner,
  proceedSpinner,
  setOtpLength,
}) {
  const list = [0, 20, 25, 35, 45, 50];
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [bonus, setBonus] = useState(0);
  const [otp, setOtp] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [quotesError, setQuotesError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [timer, setTimer] = useState(false);
  const [kycVerificationStatusFailure, setKycVerificationStatusFailure] =
    useState(false);

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },

    reset,
  } = useForm();

  const healthRequestQQ = useSelector((state) => state?.root?.healthRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const kycConfig = useSelector((state) => state?.root?.kycConfig);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimer(true);
  //   }, 30000);
  // }, [timer]);

  useEffect(() => {
    console.log("otpLengthhh", otp.length, otpLength);
    if (otp.length === otpLength) {
      submitOtp();
    }
  }, [otp]);

  const submitOtp = async () => {
    try {
      setVerificationFailed(false);
      console.log("ottptpppp", otp);

      const newData = {
        customerLeadId: motorRequest?.customerLeadId,
        caseId: motorRequest?.caseId,
        otp: otp,
        insurerId: selectedPlan?.insurerId,
        vehicleType: motorRequest?.vehicleType,
      };

      const verifyKyc = await verifyKycOtp(newData);
      console.log("verifyKyc", verifyKyc);

      if (verifyKyc?.kycStatus === "failure" && verifyKyc?.redirectionUrl) {
        setRedirectionUrl(verifyKyc?.redirectionUrl);
        toastFailure(
          "Your KYC has been failed. Redirecting you to insurer portal"
        );

        setTimeout(() => {
          window.open(verifyKyc?.redirectionUrl, "_blank");
        }, 1500);

        dispatchMotorQuote("kycStatus", "failure");

        const newMotorRequestData = {
          ...motorRequest,
          isRedirection: true,
          kycStatus: "failure",
        };

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          processDiscription: "kycAndUserDetails",
          step: "step8",
          step8: {
            ...processFlowObjMotor.step8,
            motorRequest: { ...newMotorRequestData },
            kycAndUserDetails: newData,
            kycConfig: kycConfig,
            planDetails: selectedPlan,
          },
        };

        processFlow(dataForFlow);
      } else if (verifyKyc?.kycStatus === apiKycStatus.success) {
        dispatchProcessFlowMotor("step", "step8");

        dispatchMotorQuote("kycStatus", "completed");
        dispatchMotorQuote("kycData", verifyKyc?.kycData);

        toast.success("KYC Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setKycStatus(true);

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: { ...motorRequest },
          processDiscription: "kycAndUserDetails",
          step: "step8",
          step8: {
            ...processFlowObjMotor.step8,
            motorRequest: { ...motorRequest },
            kycConfig: kycConfig,
            planDetails: selectedPlan,
          },
        };

        console.log("dataForFlow", dataForFlow);
        toggle();
        processFlow(dataForFlow);
      } else if (
        verifyKyc?.kycStatus === "failure" &&
        verifyKyc?.insurerKycResponse?.vStatusMessage ===
          "OTP Verification Failed"
      ) {
        setVerificationFailed(true);
      } else {
        toastFailure("Something went wrong. Please try again.");
        setKycVerificationStatusFailure(true);
      }
    } catch (error) {
      console.log("Error in submitOtp:", error);
      toastFailure("Something went wrong. Please try again.");
      setKycVerificationStatusFailure(true);
    }
  };
  const reSubmitKyc = async () => {
    try {
      setSpinner(true);
      const response = await getKyc(
        motorRequest,
        selectedPlan,
        motorRequest?.kycFields
      );
      console.log("responseee", response);
      setSpinner(false);

      if (
        response?.data?.isOtpRequired &&
        response?.data?.kycStatus === apiKycStatus.pending
      ) {
        setOtp("");
        setOtpLength(response?.data?.maxOtpLengthAllowed);
        setKycVerificationStatusFailure(false);

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: {
            ...motorRequest,
          },
          processDiscription: "kycAndUserDetails",
          step: "step8",
          step8: {
            ...processFlowObjMotor.step8,
            motorRequest: {
              ...motorRequest,
              kycStatus: apiKycStatus.pending,
              kycType: motorRequest?.kycType,
            },
            kycConfig: kycConfig,
            planDetails: selectedPlan,
          },
        };

        console.log("dataForFlow", dataForFlow);
        processFlow(dataForFlow);
        return;
      } else if (response?.data?.kycStatus === apiKycStatus.success) {
        dispatchProcessFlowMotor("step", "step8");

        const newData = {
          ...motorRequest,
          kycStatus: "completed",
          kycType: motorRequest?.kycType,
        };

        console.log("newData", newData);
        dispatchMotorQuote("kycStatus", "completed");
        dispatchMotorQuote("kycData", response?.data?.kycData);

        toast.success("KYC Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setKycStatus(true);

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: { ...motorRequest },
          processDiscription: "kycAndUserDetails",
          step: "step8",
          step8: {
            ...processFlowObjMotor.step8,
            motorRequest: {
              ...newData,
            },
            kycConfig: kycConfig,
            planDetails: selectedPlan,
          },
        };

        console.log("dataForFlow", dataForFlow);
        processFlow(dataForFlow);
      }
    } catch (error) {
      setKycVerificationStatusFailure(true);
      console.log("Error in reSubmitKyc:", error);
      setSpinner(false);
      toastFailure("Something went wrong. Please try again.");
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      <ModalBody>
        <form onSubmit={handleSubmit(submitOtp)}>
          <div class="p-2">
            <div class="gsc_modalWrapper">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
              {kycVerificationStatusFailure ? (
                <div class="v2_btngrp mb-3">
                  <div class="wrapper mx-3">
                    <button
                      type="button"
                      onClick={() => reSubmitKyc()}
                      name="submitBtn"
                      class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                    >
                      {spinner ? (
                        <div
                          class="spinner-border text-light"
                          role="status"
                        ></div>
                      ) : (
                        <div>Re-Submit Kyc</div>
                      )}
                    </button>
                  </div>
                  <div class="wrapper mx-3x">
                    <button
                      type="button"
                      onClick={() => {
                        toggle();
                        setProceedSpinner(false);
                      }}
                      name="submitBtn"
                      class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                    >
                      <div>Change Details</div>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="modal_heading w-100 mb-4">
                    <h4 className="text-center fs-6">
                      Please Enter OTP sent on {motorRequest?.mobileNumber}
                    </h4>
                    {/* <p
                  className="text-center fw-bold"
                  // onClick={() => editMobileNumber()}
                  style={{ color: "#59b67c", cursor: "pointer" }}
                >
                  Edit Number
                </p> */}
                  </div>
                  <div className="mb-3">
                    <div className="d-flex w-100 justify-content-center align-items-center">
                      <OtpInput
                        value={otp}
                        inputStyle={{
                          borderRadius: "5px",
                          width: "50px",
                          height: "50px",
                          border: "1px solid black",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                          gap: "10px",
                        }}
                        // containerStyle={{
                        //   width: "70%",
                        // }}
                        onChange={(val) => {
                          setOtp(val);
                          setVerificationFailed(false);
                        }}
                        numInputs={otpLength}
                        renderSeparator={<span> - </span>}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  <div>
                    {quotesError === true && verificationSuccess === false && (
                      <p className="text-danger text-center">
                        Unable to Provide quotes for you!!
                      </p>
                    )}
                    {verificationSuccess === true ? (
                      <p className="text-success text-center">
                        Your Otp has been successfully Verified
                      </p>
                    ) : verificationFailed === true ? (
                      <p className="text-danger text-center">
                        Please enter a valid Otp
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="v2_btngrp mb-3">
                    <div class="wrapper">
                      {/* <button
                    type="submit"
                    name="submitBtn"
                    class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                  >
                    {spinner ? (
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    ) : (
                      "Submit"
                    )}
                  </button> */}
                      {/* {timer && (
                    <div>
                      <p className="central-login-Receive-OTP text-center">
                        In Case of didn't receive an OTP?{" "}
                        <span
                          className="text-success ms-1 fw-bold"
                          style={{ color: "#59b67c", cursor: "pointer" }}
                          onClick={() => {
                            sendOtp(resendObj);
                            setTimer(false);
                          }}
                        >
                          Resend
                        </span>
                      </p>
                    </div>
                  )} */}
                      {select == "no" && (
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="text-decoration-underline">
                            I don't Know my NCB
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default OtpModel;
